/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect }from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import Present from "views/Present.js"

var ps;

function Share(props) {
  const [classRoutes, setClassRoutes] = useState(null);
  const [backgroundColor, setBackgroundColor] = React.useState("white");
  const [activeColor, setActiveColor] = React.useState("info");
  const user = useSelector(selectUser); 
  const routes = [
    {
      "collapse": true,
      "name": "Present Classes",
      "icon": "nc-icon nc-book-bookmark",
      "state": "pagesCollapse",
    },
  ]

  useEffect(() => {
    getClassRoutes();
  },[user])

  // const fullPages = React.useRef();
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(fullPages.current);
  //   }
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });

  const mainPanel = React.useRef();

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      // Check if mainPanel.current is defined before initializing PerfectScrollbar
      if (mainPanel.current) {
        ps = new PerfectScrollbar(mainPanel.current);
      }
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        // Check if ps is defined before destroying it
        if (ps) {
          ps.destroy();
        }
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, [mainPanel]); // Include mainPanel in the dependency array

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // Check if mainPanel.current is defined before setting scrollTop
    if (mainPanel.current) {
      mainPanel.current.scrollTop = 0;
    }
  }, [location]);

  const getClassRoutes = () => {
    const classRoutesArray = [];
    user && user.classes.map((item) => {
      // Limit the name to 23 characters and add "..." if it exceeds that length
      const truncatedName = item.className
        ? item.className.length > 23
          ? item.className.substring(0, 23) + "..."
          : item.className
        : item.classId; //If className is null or undefined, use classId instead.
  
      const classPath = "/" + item.classId;

      classRoutesArray.push({
        path: "/share/" + user.shareCode + classPath,
        name: truncatedName, // Use the truncated name
        mini: item.period != null ? item.period.replace(/ .*/, "") : ">",
        component: Present,
        layout: "/share",
        classId: item.classId,
        showMarquee: item.showMarquee,
        showClock: item.showClock,
        visible: item.active ? true : false,
      });
    });
    const newRoutes = [...routes];
    newRoutes[0].views = classRoutesArray;
    setClassRoutes(newRoutes);
  };

  const getRoutes = (routes) => {
    return routes && routes?.map((prop, key) => {
      {console.log("prop => ", prop)}
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/share") {
        return (
          <Route
            path={prop.layout + "/" + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
     <div className="wrapper">
        <Sidebar
          {...props}
          routes={classRoutes}
          bgColor={backgroundColor}
          activeColor={activeColor}
          navigationVisible={true}
        />
        <Route exact path="/share"></Route>
        <div className={"main-panel"} ref={mainPanel} style={{background: ''}}>
          {/* <AdminNavbar {...props} handleMiniClick={handleMiniClick} /> */}
          <Switch>
            { user && user.classes.length < 1 ?
              <Route path="/share"></Route>
            : user && <Route path="/share"><Present props={{classId: user?.classes[0].classId, showMarquee: user?.classes[0].showMarquee, showClock: user?.classes[0].showClock}}/></Route>
            }
            {getRoutes(classRoutes)}
            {/* <Redirect exact from="/" to={classRoutes[0].views[0].path} /> */}
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Share;
