import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { collection, query, where, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { Label, FormGroup, FormFeedback, Badge, Card, CardHeader, Table, CardBody, Container, Input, CardTitle, CardImg, Row, Col, UncontrolledTooltip, ButtonDropdown, Button, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Dropdown, Modal, ModalBody, ModalHeader, CardText } from "reactstrap";

const AdminPanel = () => {
  const user = useSelector(selectUser);
  const [emailToSearch, setEmailToSearch] = useState();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [trialChangeAlert, setTrialChangeAlert] = useState(false);
  const [inputUsed, setInputUsed] = useState()
  const [searchDates, setSearchDates] = useState(
      { 
        fromDate: {date: '', seconds: ''}, 
        toDate: {date: '', seconds: ''} 
      }
    )

    const [searchByDateUsers, setSearchByDateUsers] = useState([]);

  const searchForUserByEmail = async () => {
    setTrialChangeAlert(false)
    setLoading(true);
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", emailToSearch)); // Renamed to q to avoid shadowing
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Assume there's only one user with the email
        const userDoc = querySnapshot.docs[0].data();
        setUserData(userDoc);
      } else {
        console.log('No such user!');
        setUserData(null);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
    setLoading(false);
  };

const searchForUsersByDateRange = async () => {
    setSearchByDateUsers([])
    if (searchDates.fromDate.date && searchDates.toDate.date) {
      setLoading(true);
      try {
        const usersRef = collection(db, "users");
        const q = query(
          usersRef, 
          where("accountCreated.seconds", ">=", searchDates.fromDate.seconds), 
          where("accountCreated.seconds", "<=", searchDates.toDate.seconds)
        );
        console.log("query => ", q);
  
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          // Map each document to the searchByDateObject structure
          const users = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              firstName: data.firstName || "",
              lastName: data.lastName || "",
              email: data.email || "",
              accountCreated: data.accountCreated || "",
              premium: data.premium || false,
              trial: data.trial || false,
              leadSource: data.leadData?.leadSource || "",
              uid: data.uid || doc.id // Fallback to doc ID if uid is not available
            };
          });
  
          // Set the state with the mapped users
          setSearchByDateUsers(users);
  
          console.log("Mapped users => ", users);
        } else {
          console.log("No users found!");
          setSearchByDateUsers([]); // Clear state if no users are found
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
      setLoading(false);
    }
  };

const endTrial = async (fromTable = false, uid = null) => {
    try {
      // Get the correct user ID depending on whether fromTable is true
      const userId = fromTable ? uid : userData.uid;
  
      // Reference to the user's document in Firestore
      const docRef = doc(db, "users", userId);
  
      // Fetch the user data from Firestore if fromTable is true
      let userCopy;
      if (fromTable) {
        const userDoc = await getDoc(docRef);
        if (userDoc.exists()) {
          userCopy = userDoc.data();
        } else {
          console.log('No such user!');
          return;
        }
      } else {
        // Create a deep copy of userData if fromTable is false
        userCopy = JSON.parse(JSON.stringify(userData));
      }
  
      // Update trial status to false
      userCopy.trial = false;
  
      // Update the document in Firestore with the modified userCopy
      await setDoc(docRef, userCopy);
  
      console.log('Trial status updated successfully.');
  
      // Set trial change alert
    //   setTrialChangeAlert(true);
  
    //   setTimeout(() => {
    //     setTrialChangeAlert(false);
    //   }, 2000);
  
    } catch (error) {
      console.error('Error updating trial status:', error);
    }
  };

  const handleSearchDateChange = (value, type) => {
    setInputUsed("date")
    // Create a Date object from the input value
    const date = new Date(value);
  
    // Get the timestamp in seconds
    const seconds = Math.floor(date.getTime() / 1000);
  
    // Update the state based on whether 'fromDate' or 'toDate' is being updated
    setSearchDates((prevDates) => ({
      ...prevDates,
      [type]: {
        date: value,        // Keep the input date string
        seconds: seconds,   // Store the timestamp in seconds
      },
    }));
  };

  const formatDate = (timestamp) => {
    // Check if the timestamp is a Firestore object (with 'seconds' and 'nanoseconds')
    if (timestamp && typeof timestamp === 'object' && 'seconds' in timestamp && 'nanoseconds' in timestamp) {
      // Convert Firestore timestamp to milliseconds
      const milliseconds = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);
      // Create a Date object from the milliseconds
      const date = new Date(milliseconds);
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  
    // If it's a standard timestamp, treat it as a regular Date
    const date = new Date(timestamp);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - 1); 

  const calculateLeadSourceMetrics = (leadSource, users) => {
    const totalUsers = users.length;
    const totalLeadSourceUsers = users.filter(user => user.leadSource === leadSource).length;
    const premiumLeadSourceUsers = users.filter(user => user.leadSource === leadSource && user.premium === true).length;
  
    const percentageLeadSourceUsers = totalUsers > 0 ? Math.round((totalLeadSourceUsers / totalUsers) * 100) : 0;
    const percentagePremiumLeadSourceUsers = totalLeadSourceUsers > 0 ? Math.round((premiumLeadSourceUsers / totalLeadSourceUsers) * 100) : 0;
  
    return `${totalLeadSourceUsers} - ${percentageLeadSourceUsers}% - ${percentagePremiumLeadSourceUsers}%`;
  };

  return (
      <>
        {user && user.settings.role === "admin" ? (
            <div className="content">
            <Row>
                <h1>Admin Panel</h1>
            </Row>
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <h2>User Finder</h2>
                        </CardHeader>
                        <Row style={{margin: '15px 10px 0px 10px'}} >
                            <Col md="2" className="d-flex align-items-end">
                                <label>First Name:</label>
                                <Input
                                    type="text"
                                    value={null}
                                    // onChange={(e) => setEmailToSearch(e.target.value)} // Access input value correctly
                                    // onKeyDown={(e) => e.key === "Enter" ? searchForUserByEmail() : null} 
                                />
                            </Col>
                            <Col md="2" className="d-flex align-items-end">
                                <label>Last Name:</label>
                                <Input
                                    type="text"
                                    value={null}
                                    // onChange={(e) => setEmailToSearch(e.target.value)} // Access input value correctly
                                    // onKeyDown={(e) => e.key === "Enter" ? searchForUserByEmail() : null} 
                                />
                            </Col>
                            <Col md="3" className="d-flex align-items-end">
                                <label>User Email:</label>
                                <Input
                                    type="text"
                                    value={emailToSearch}
                                    onChange={(e) => {
                                        setEmailToSearch(e.target.value); 
                                        setInputUsed("email");
                                    }}
                                    onKeyDown={(e) => e.key === "Enter" ? searchForUserByEmail() : null} 
                                />
                            </Col>
                            <Col md="2" className="d-flex align-items-end">
                                <label>From Date:</label>
                                <Input
                                    type="date"
                                    fromDate="fromDate"
                                    value={searchDates.fromDate.date}
                                    onChange={(e) => handleSearchDateChange(e.target.value, 'fromDate')} // Pass the type
                                />
                            </Col>
                            <Col md="2" className="d-flex align-items-end">
                                <label>To Date:</label>
                                <Input
                                    type="date"
                                    id="toDate"
                                    value={searchDates.toDate.date}
                                    onChange={(e) => handleSearchDateChange(e.target.value, 'toDate')} // Pass the type
                                />
                            </Col>
                            <Col md="1" className="d-flex align-items-end">
                                <Button onClick={inputUsed === "date" ? searchForUsersByDateRange : inputUsed === "email" ? searchForUserByEmail : null} disabled={loading} style={{height: '32px', margin: '0px'}}>
                                    {loading ? 'Searching...' : 'Search'}
                                </Button>
                            </Col>
                        </Row>
                        <hr></hr>
                        {userData ? (
                        <Row className="admin-panel-kpi-tiles">
                            <Col>
                                <Card>
                                    <h4>User Data:</h4>
                                    <p><b>Email:</b> {userData.email}</p>
                                    <p><b>First Name:</b> {userData.firstName}</p>
                                    <p><b>Last Name:</b> {userData.lastName}</p>
                                    <p><b>Account Created:</b> {userData.accountCreated ? new Date(userData.accountCreated.seconds * 1000).toLocaleString() : 'N/A'}</p>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <h4>Subscription:</h4>
                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                                      <p><b>Premium:</b> {userData.premium ? "True" : "False"}</p><i className='fa fa-repeat' style={{marginLeft: '5px', cursor: 'pointer'}}/>
                                    </div>
                                    <p><b>Trial:</b> {userData.trial ? "True" : "False"}</p>
                                    {userData.trial &&
                                        <Button
                                            onClick={() => endTrial()}
                                        >
                                            {!trialChangeAlert ? "End Trial?" : "Trial Ended Successfully"}
                                            {trialChangeAlert && <i className="fa fa-check" style={{ marginLeft: '10px', color: 'white' }} />}
                                        </Button>
                                    }
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <h4>Lead Data:</h4>
                                    <p><b>Lead Source:</b> {userData.leadData?.leadSource}</p>
                                    <p><b>Mobile:</b> {userData.leadData?.mobile ? "True" : "False"}</p>                                  
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <h4>Settings:</h4>
                                    <p><b>Active Dates:</b> {userData.settings?.activeDates ? "True" : "False"}</p>
                                    <p><b>Altered Schedule Mode:</b> {userData.settings?.alteredScheduleMode ? "True" : "False"}</p>
                                    <p><b>Multi-week:</b> {userData.settings?.multiWeek ? "True" : "False"}</p>
                                    <p><b>Different Times:</b> {userData.settings?.differentTimes ? "True" : "False"}</p>
                                    <p><b>Current Week:</b> {userData.settings?.currentWeek}</p>
                                </Card>
                            </Col>
                        </Row>
                        )
                        :
                        null
                    }
                    </Card>
                </Col>
            </Row>
            {searchByDateUsers.length ?
            <Row>
                <Col>
                <Card>
                    <Row className="admin-panel-kpi-tiles" style={{margin: '10px'}}>
                        <Col>
                            <Card>
                                <Row style={{margin: '10px'}}>
                                    <h6>Totals:</h6> 
                                </Row>
                                <Row>
                                    <Col style={{marginLeft: '20px'}}>
                                        <div>
                                            <b>Total:</b> {searchByDateUsers.length}
                                        </div>
                                        <div>
                                            <b>Total Paid:</b>  {searchByDateUsers.filter(user => user.premium === true).length}
                                        </div>
                                        <div>
                                            <b>Percent Paid:</b>  {Math.round((((searchByDateUsers.filter(user => user.premium === true).length)/searchByDateUsers.length)*100)) + "%"}
                                        </div>
                                        <div>
                                            <b>Percent Trial Ended:</b>  {Math.round((((searchByDateUsers.filter(user => user.trial === false).length)/searchByDateUsers.length)*100)) + "%"}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>  
                        </Col>
                        <Col>
                            <Card>
                                <Row style={{margin: '10px'}}>
                                <h6>Lead Source: </h6> 
                                </Row>
                                <Row>
                                  <Col style={{ marginLeft: '20px' }}>
                                    <div>
                                      <b>Facebook Ad: </b> 
                                      {calculateLeadSourceMetrics("Facebook Ad", searchByDateUsers)}
                                    </div>
                                    <div>
                                      <b>Instagram Ad: </b> 
                                      {calculateLeadSourceMetrics("Instagram Ad", searchByDateUsers)}
                                    </div>
                                    <div>
                                      <b>Colleague/Friend: </b> 
                                      {calculateLeadSourceMetrics("Colleague/Friend", searchByDateUsers)}
                                    </div>
                                    <div>
                                      <b>Convention Booth: </b> 
                                      {calculateLeadSourceMetrics("Convention Booth", searchByDateUsers)}
                                    </div>
                                  </Col>
                                  <Col>
                                    <div>
                                      <b>Facebook Group: </b> 
                                      {calculateLeadSourceMetrics("Facebook Group", searchByDateUsers)}
                                    </div>
                                    <div>
                                      <b>Google Search: </b> 
                                      {calculateLeadSourceMetrics("Google Search", searchByDateUsers)}
                                    </div>
                                    <div>
                                      <b>Other: </b> 
                                      {calculateLeadSourceMetrics("Other", searchByDateUsers)}
                                    </div>
                                    <div>
                                      <b>No Answer: </b> 
                                      {calculateLeadSourceMetrics("", searchByDateUsers)}
                                    </div>
                                  </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center" id="j34820asioa" style={{width: '9%'}}>First Name</th>
                      <th className="text-center" id="basdf098bqw" style={{width: '9%'}}>Last Name</th>
                      <th className="text-center" id="as9d8ifiq0b" style={{width: '14%'}}>Account Created</th>
                      <th className="text-center" id="i94i8nb9eeq" style={{width: '20%'}}>Email</th>
                      <th className="text-center" id="kslwir90909" style={{width: '9%'}}>Lead Source</th>
                      <th className="text-center" id="s2234insidv" style={{width: '9%'}}>Premium</th>
                      <th className="text-center" id="kslwir90909" style={{width: '9%'}}>Trial</th>
                      <th className="text-center" style={{width: '9%'}}>Edit</th>
                      <UncontrolledTooltip delay={0} target={"j34820asioa"}>Have you entered birthdays in your roster for this class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"basdf098bqw"}>Do you want to use Auto-Happy Birthday for this class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"as9d8ifiq0b"}>When should the Happy Birthday popup launch during your class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"i94i8nb9eeq"}>Coming Soon...</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"s2234insidv"}>Coming Soon...</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"kslwir90909"}>How long do you want the Happy Birthday popup to appear on the screen before automatically closing?</UncontrolledTooltip>
                    </tr>
                  </thead>
                  {searchByDateUsers && searchByDateUsers.length > 0 ? searchByDateUsers.map((user, index) => {
                    return(
                      <tbody key={index}>
                        <tr>
                          <td className="text-center" >{user.firstName}</td>
                          <td className="text-center" >{user.lastName}</td>
                          <td className="text-center" >{formatDate(user.accountCreated)}</td>
                          <td className="text-center" >{user.email}</td>
                          <td className="text-center" >{user.leadSource}</td>
                          <td className="text-center">{user.premium ? <Badge color="success">Yes</Badge> : <Badge color="danger">No</Badge>}</td>                          
                          <td className="text-center">{user.trial ? <Badge color="success">Yes</Badge> : <Badge color="danger">No</Badge>}</td>                          
                          <td className="text-center">
                            <Button 
                                disabled={
                                !user.trial || 
                                new Date(user.accountCreated.seconds * 1000) > oneMonthAgo
                                } 
                                style={{ height: '20px', padding: '3px' }}
                                onClick={() => endTrial(true, user.uid)}
                            >
                                End Trial?
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    )}) : null}
                </Table>
                </Card>
                </Col>
            </Row>
            : null
            }
            </div>
        )
        : 
        (
            <div className="content">
                <h1>What are you doing here!? I'm calling the cops!</h1>
            </div>
        )
        }
    </>
  );
};

export default AdminPanel;
