import React, { useState, useEffect, useRef } from 'react'
import { doc, getDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { NavLink } from 'react-router-dom';
import { Card, CardBody, Row, Col, Navbar, NavbarBrand, Carousel, Container, CardImgOverlay, CardText, CardTitle,
     NavItem, UncontrolledAccordion, AccordionItem,
      AccordionBody, Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader, CardFooter, CardImg } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";
import logoWithText from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-with-text.png";
import { Link } from 'react-router-dom';
import LandingCarousel from 'components/LandingCarousel';
import featureAnnouncement from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-announcement.svg";
import featureAutoAdvance from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-advance.svg";
import featureClock from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-clock.svg";
import featureCountdownTimers from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-countdown-timers.svg";
import featureGoogleSlides from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-google-slides.svg";
import featureReminders from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-reminders.svg";
import featureStopwatch from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-stopwatch.svg";
import featureTextEditor from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-text-editor.svg";
import featureTimer from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-timer.svg";
import featureBirthday from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-happy-birthday.svg";
import featureRandomizer from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-randomizer.svg";
import featurePrint from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-print-lesson-plan.svg";
import featureAgenda from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-agenda.svg";
import featureCanva from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-canva.svg";
import featureGoogleDoc from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-google-doc.svg";
import featureShare from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-share.svg";
import logoWhite from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-white.svg";
import featurePowerPoint from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-power-point-box.svg";
import featurePDF from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-pdf-box.svg";
import TestimonialCarousel from 'components/TestimonialCarousel';

const LandingPage = () => {

    const pricingRef = useRef(null);

    const scrollToPricing = (event) => {
        event.preventDefault(); // Prevent the default anchor behavior
        if (pricingRef.current) {
          pricingRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };

    const isMobile = /Mobi/.test(navigator.userAgent);

    const initialHoverState = {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
        eight: false,
        nine: false,
        ten: false,
        eleven: false,
        twelve: false,
        thirteen: false,
        fourteen: false,
        fifteen: false,
        sixteen: false,
        seventeen: false,
        eighteen: false,
        nineteen: false
      };
      
      const [isHovered, setIsHovered] = useState(initialHoverState);

      const [testimonialData, setTestimonialData] = useState({});
      const [imageDimensions, setImageDimensions] = useState({});
      
      useEffect(() => {
        let isMounted = true;
      
        const fetchData = async () => {
          try {
            const docRef = doc(db, "testimonials", "lEvNFHdJpNmFRQp1aI1A");
            const docSnap = await getDoc(docRef);
      
            if (docSnap.exists() && isMounted) {
              const testimonialData = docSnap.data();
    
            //   const sortedKeys = Object.keys(testimonialData).sort((a, b) => parseFloat(a) - parseFloat(b));
    
            //   const sortedData = sortedKeys.reduce((acc, key) => {
            //       acc[key] = testimonialData[key];
            //       return acc;
            //   }, {});
    
              setTestimonialData(testimonialData);
    
            } else {
              console.log("No such document!");
            }
          } catch (error) {
            console.error('Error fetching document:', error);
          }
        };
      
        fetchData();
      
        // Cleanup function to set isMounted to false when the component is unmounted
        return () => {
          isMounted = false;
        };
      }, []);

      const handleImageLoad = (id, event) => {
        const { naturalWidth, naturalHeight } = event.target;
        const aspectRatio = naturalWidth / naturalHeight;
        const newWidth = 180 * aspectRatio; // Calculate width based on fixed height
        setImageDimensions(prevDimensions => ({
            ...prevDimensions,
            [id]: newWidth
        }));
    };

    const handleImageClick = (url) => {
        window.open(url, '_blank'); // Open the image URL in a new tab
    };

  return (
    <>
    { !isMobile ? (
            <div style={{ backgroundColor: 'white' }}>
            <Row style={{justifyContent: 'center', backgroundColor: "white"}}>
                <Card className='border' style={{width: '80%', marginTop: '1%'}}>
                    <Navbar className='landing-navbar'>
                        <NavbarBrand>
                            <a href={"https://www.lessonlaunchpad.com"}>
                            <img src={logoWithText} alt="lesson-launchpad-logo" style={{ width: '175px', height: 'auto' }}/>
                            </a>
                        </NavbarBrand>
    
                        {/* <div className="ml-auto d-flex align-items-center">
                            <NavLink to="/why-lesson-launchpad" style={{ color: 'black', marginRight: '50px' }}>
                                Why Lesson Launchpad?
                            </NavLink>
                            <NavLink to="/pricing" style={{ color: 'black', marginRight: '0px' }}>
                                Pricing
                            </NavLink>
                        </div> */}
                        
                        <div className="ml-auto d-flex align-items-center">
                            <NavLink to="/why-lesson-launchpad" style={{ color: 'black', marginRight: '50px' }}>
                                Why Lesson Launchpad?
                            </NavLink>

                            <NavLink to="#" onClick={scrollToPricing} style={{ color: 'black', marginRight: '50px' }}>
                                Pricing
                            </NavLink>

                            {/* <a onClick={scrollToPricing} style={{ color: 'black', marginRight: '50px', cursor: 'pointer' }}>Pricing</a> */}
    
                            <NavLink to="/auth/login" style={{ color: 'black', marginRight: '50px' }}>
                            Login
                            </NavLink>
    
                            <Button tag={Link} to="/auth/register" style={{ marginLeft: '10px' }}>
                            Register
                            </Button>
                        </div>
                    </Navbar>
                </Card>
            </Row>
    
    {/************************************************* HEADLINE *************************************************/}
    
            <Row style={{ height: '510px', backgroundColor: "white", alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', marginBottom: '280px' }}>
                <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '8rem', marginTop: '-1rem' }}>
                <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>Plan</i></b></div>
                </h1>
                <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '8rem', marginTop: '-3rem' }}>
                <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>Present</i></b></div>
                </h1>
                <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '8rem', marginTop: '-3rem', marginBottom: '1rem'}}>
                <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>Automate</i></b></div>
                </h1>
                <h1 style={{ color: '#004aad', fontWeight: '1000', fontSize: '2.25rem', marginTop: '-1rem', marginBottom: '1rem'}}>
                <b>The Ultimate Classroom Automation Tool for Teachers</b>
                </h1>
                <Button
                tag={Link}
                to="/auth/register"
                // className="landing-btn-no-hover"
                style={{
                    // backgroundColor: 'rgb(11, 192, 223)',
                    color: 'white',
                    height: '50px',
                    width: '200px',
                    fontSize: '1.5rem',
                }}
                >
                TRY IT NOW
                </Button>
            </div>
            {/* <img
                className='landing-page-macbook'
                src={macbook}
                alt="lesson-launchpad-on-macbook"
            /> */}
            </Row>
    
    {/************************************************* Carousel *************************************************/}
    
            <LandingCarousel />

    {/************************************************* About *************************************************/}
    
        <Row style={{ height: '350px', backgroundColor: "#004aad", alignItems: 'center', justifyContent: 'center' }}>
            <Col md="5">
                <div style={{ textAlign: 'right', marginLeft: '10vw' }}>
                    <h1 style={{ color: 'white', fontWeight: '1000', fontSize: '2rem' }}>
                    <b>Why Lesson Launchpad?</b>
                    </h1>
                </div>
            </Col>
            <Col md="7">
                <div style={{ textAlign: 'left', marginRight: '10vw' }}>
                    <h1 style={{ color: 'white', fontWeight: '500', fontSize: '1.1rem', fontStyle: 'italic', lineHeight: '1.5' }}>
                    When I was a band director, I always wanted a better solution to the "agenda slide". I wanted to be able to present more information, but in a neat uncluttered way. I wanted a clock visible on the screen, a scrolling announcement marquee, an automatic timer to pop up to alert my students when it's time to be in their seats. And, I wanted it to do it all automatically - change classes for me at the correct time, launch reminders, etc. so that way I would be free to teach instead of fiddling with the computer. Even though I'm no longer a band director, I built Lesson Launchpad as a solution to those problems and more.
                    </h1>
                    <h1 style={{ color: 'white', fontWeight: '500', fontSize: '1.1rem' }}>
                    Michael - <em>Lesson Launchpad Founder</em>
                    <br></br>
                    </h1>
                </div>
            </Col>
        </Row>

    
    {/************************************************* FEATURES *************************************************/}

    <div style={{ textAlign: 'center', marginBottom: '50px', marginTop: '100px' }}>    
    <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '4rem' }}>
            <b>Features:</b>
        </h1>
    </div>
    <Container>
        <Row xs="3" className='my-5'>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, one: !prev.one }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, one: !prev.one }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureAutoAdvance} alt="auto-advance" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Auto Class Advance
                    </CardHeader>
                    {isHovered.one && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            Lesson Launchpad will automatically transition to your next class when it's time. You can be free to take care of any tasks instead of fiddling with your computer and trying to get the next class's content on the screen.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, two: !prev.two }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, two: !prev.two }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureReminders} alt="auto-reminders" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Auto Reminders
                    </CardHeader>
                {isHovered.two && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            Always forget to take attendance? Have a student that needs to get dismissed at a certain time? Schedule custom reminders that will automatically pop up on screen during class.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, three: !prev.three }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, three: !prev.three }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureCountdownTimers} alt="auto-timers" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Auto Timers
                    </CardHeader>
                {isHovered.three && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                        Schedule custom timers that will automatically appear to let your students know how much longer they have to complete a task. Students need to be in their seats by two minutes after the bell? Here's your solution.
                    </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, fourteen: !prev.fourteen }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, fourteen: !prev.fourteen }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureAgenda} alt="auto-agenda" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Auto Agenda
                    </CardHeader>
                {isHovered.fourteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                        Want an Agenda that keeps you on track? Schedule each part of your lesson and Lesson Launchpad will automatically keep you on track by showing a countdown for each part of your lesson.
                    </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, four: !prev.four }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, four: !prev.four }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureAnnouncement} alt="scrolling-marquee" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Scrolling Marquee
                    </CardHeader>
                {isHovered.four && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                        A scrolling marquee for your announcements, objectives, or anything else you want to use it for.
                    </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, five: !prev.five }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, five: !prev.five }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureClock} alt="clock" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Clock
                    </CardHeader>
                {isHovered.five && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            A simple clock to keep them and you on task. Don't want it? You can turn it off in settings.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, six: !prev.six }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, six: !prev.six }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureTextEditor} alt="advanced-text-editor" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Advanced Text Editor
                    </CardHeader>
                {isHovered.six && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            Lesson Launchpad comes with an advanced text editor that allows you to format you lesson content in any way you want.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, seven: !prev.seven }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, seven: !prev.seven }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureTimer} alt="timer" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Timer
                    </CardHeader>
                {isHovered.seven && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            In the middle of a lesson and decide you need a timer? With Lesson Launchpad, you'll always have one available one click away.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, eight: !prev.eight }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, eight: !prev.eight }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureStopwatch} alt="stopwatch" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Stopwatch
                    </CardHeader>
                {isHovered.eight && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            In the middle of a lesson and decide you need a stopwatch? With Lesson Launchpad, you'll always have one available one click away.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, nine: !prev.nine }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, nine: !prev.nine }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureGoogleSlides} alt="google-slides-compatible" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Google Slides Compatible
                    </CardHeader>
                {isHovered.nine && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            You can easily add all of your Google Slides presentations right into Lesson Launchpad so you can get all of the features of Lesson Launchpad without having to re-create all of your lesson content.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, thirteen: !prev.thirteen }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, thirteen: !prev.thirteen }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureCanva} alt="canva-compatible" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Canva Compatible
                    </CardHeader>
                {isHovered.thirteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            You can easily add all of your Canva Projects right into Lesson Launchpad.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, ten: !prev.ten }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, ten: !prev.ten }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureBirthday} alt="auto-happy-birthday" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Auto Happy Birthday
                    </CardHeader>
                {isHovered.ten && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                        Bad at remembering student's birthdays? Let Lesson Launchpad help you out. Lesson Launchpad will automatically pop-up a happy birthday message during class.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, eleven: !prev.eleven }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, eleven: !prev.eleven }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureRandomizer} alt="randomizer" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Randomizer
                    </CardHeader>
                {isHovered.eleven && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                        Pick a random student, put students into groups, randomize custom data.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, twelve: !prev.twelve }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, twelve: !prev.twelve }))}
                >
                    <CardBody className='text-center'>
                        <img src={featurePrint} alt="print-lesson-plan" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Print Lesson Plan
                    </CardHeader>
                {isHovered.twelve && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            Easily turn your Lesson Launchpad content into lesson plans to share with your administrators.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, fifteen: !prev.fifteen }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, fifteen: !prev.fifteen }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureGoogleDoc} alt="google-doc" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Google Doc Compatible
                    </CardHeader>
                {isHovered.fifteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            Open and Edit Google Docs or Google Sheets right inside Lesson Launchpad.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, seventeen: !prev.seventeen }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, seventeen: !prev.seventeen }))}
                >
                    <CardBody className='text-center'>
                        <img src={featureShare} alt="share" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Sharable
                    </CardHeader>
                {isHovered.seventeen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                    <CardText>
                            Want to share your Lesson Launchpad content with parents or students through a link? You can do that with Lesson Launchpad.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, eighteen: !prev.eighteen }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, eighteen: !prev.eighteen }))}
                >
                    <CardBody className='text-center'>
                        <img src={featurePDF} alt="share" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        Use PDF's
                    </CardHeader>
                {isHovered.eighteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                        <CardText>
                            Add your own PDF's to use as lesson content.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onMouseEnter={() => setIsHovered((prev) => ({ ...prev, sixteen: !prev.sixteen }))}
                    onMouseLeave={() => setIsHovered((prev) => ({ ...prev, sixteen: !prev.sixteen }))}
                >
                    <CardBody className='text-center'>
                        <img src={featurePowerPoint} alt="share" style={{width: '100px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='h5 text-center' id="landing-feature">
                        PowerPoint Compatible
                    </CardHeader>
                {isHovered.sixteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '15px'}} >
                        <CardText>
                            You can easily add all of your PowerPoint presentations right into Lesson Launchpad so you can get all of the features of Lesson Launchpad without having to re-create all of your lesson content.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
          
        </Row>
    </Container>
    
            
    
    {/************************************************* SOCIAL PROOF *************************************************/}

        {/* <Row xs="4" style={{ height: '475px', backgroundColor: "#004aad", alignItems: 'center', justifyContent: 'center' }}>
            {Object.entries(testimonialData).map(([key, data]) => (
                <Card key={key} style={{ margin: '20px' }}>
                    <div style={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        height: '180px',
                        overflow: 'hidden',
                        margin: '10px'
                    }}>
                        <img
                            alt="Card"
                            src={data.picture}
                            height={180}
                            width={imageDimensions[key] || 0}
                            onLoad={(event) => handleImageLoad(key, event)}
                            onClick={() => handleImageClick(data.picture)} // Add onClick handler
                            style={{ 
                                objectFit: 'cover',
                                cursor: 'pointer' // Change cursor to pointer to indicate it's clickable
                            }}                        />
                    </div>
                    <CardBody style={{marginLeft: '10px', marginRight: '10px'}}>
                        <i>{`"${data.testimonial}"`}</i>
                    </CardBody>
                    <CardFooter style={{left: '90px'}}>
                        {data.firstName} {data.lastName}.
                        <br></br>
                        {data.gradeLevel} {data.subject}, {data.state}
                    </CardFooter>
                </Card>
            ))}
        </Row> */}
        <TestimonialCarousel testimonialData={testimonialData} imageDimensions={imageDimensions} handleImageLoad={handleImageLoad} handleImageClick={handleImageClick}/>

    
    {/************************************************* PRICING *************************************************/}
    
    <Row style={{ height: '550px', alignItems: 'center', justifyContent: 'center' }}>
        <div ref={pricingRef} id="pricing-section" style={{ textAlign: 'center', marginBottom: '100px', marginTop: '50px' }}>
            <h1 style={{ color: 'rgb(11, 192, 223)'}}>
            <b><i> Cool! How much does it cost?</i></b>
            </h1>
            <h3 style={{ color: '#004aad', marginBottom: '5px', fontWeight: '600'}} >
            Unlock the power of Lesson Launchpad at an unbeatable value:
            </h3>
            <h5 style={{ color: '#004aad'}} >
            <div>Just $2.99 per month.</div>
            </h5>
            <h5 style={{ color: '#004aad'}} >
            <div>Or enjoy a full year of access for only $29.99.</div>
            </h5>
            <h5 style={{ color: '#004aad'}} >
            </h5>
            <h3 style={{ color: '#004aad', marginBottom: '5px', marginTop: '30px', fontWeight: '600'}} >
            But wait, there's more:
            </h3>
            <h5 style={{ color: '#004aad'}} >
            <div>Dive in risk-free with a 30-day full-featured trial.</div>
            </h5>
            <h5 style={{ color: '#004aad'}} >
            <div>No credit card required until you're ready.</div>
            </h5>
            <hr></hr>
             <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: '#004aad', height: '75px', width: 'auto', fontSize: '20px', border: 'solid' }}>
                <div className="d-flex align-items-center">
                <img src={logo} alt="lesson-launchpad-logo" style={{ width: '50px', height: 'auto', marginRight: '15px' }} />
                TRY IT NOW
                </div>
            </Button>
        </div>
    </Row>

    {/************************************************* Footer *************************************************/}

    <Row style={{ height: '100px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
            <div style={{margin: '20px', color: 'white'}}>
                <NavLink to="/privacy" style={{color: 'white'}}>Privacy Policy</NavLink>
            </div>
            <img
                src={logoWhite}
                alt="lesson-launchpad-white-logo"
                style={{ height: '75px'}}
            />
            <div style={{margin: '20px', color: 'white'}}>
                <NavLink to="/contact" style={{color: 'white'}}>Contact</NavLink>
            </div>
            {/* <div style={{margin: '20px', color: 'white'}}>
                <div style={{color: 'white'}}>michael@lessonlaunchpad.com</div>
            </div> */}
    </Row>
    
    
    {/************************************************* DEMO *************************************************/}
    
            {/* <Row style={{minHeight: '2000px', backgroundColor: 'pink'}}>
                <div style={{ backgroundColor: 'red', height: '800px', width: '1600px', margin: '50px', padding: '10px'}}>
                    <PresentDemoForLandingPage />
                </div>
            </Row> */}
    
    
    {/************************************************* FOOTER *************************************************/}
    
            {/* <Row className="ml-auto" style={{ backgroundColor: "rgb(11, 192, 223)", height: '100px', justifyContent: 'center' }}>
            <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: "rgb(11, 192, 223)", height: '75px', width: 'auto', fontSize: '20px' }}>
                <div className="d-flex align-items-center">
                <img src={logo} alt="lesson-launchpad-logo" style={{ width: '50px', height: 'auto', marginRight: '15px' }} />
                Try Lesson Launchpad NOW
                </div>
            </Button>
            </Row>
     */}
    
    
    
    
        </div>
    ) : (
        <div style={{ backgroundColor: 'white' }}>
        <Row style={{justifyContent: 'center', backgroundColor: "white"}}>
            <Card style={{width: '90%', marginTop: '2%', height: '175px'}}>
                <Navbar className='landing-navbar'>
                    <NavbarBrand>
                        <a href={"https://www.lessonlaunchpad.com"}>
                        <img src={logoWithText} alt="lesson-launchpad-logo" style={{ width: '400px', height: 'auto' }}/>
                        </a>
                    </NavbarBrand>
                    
                    <div className="ml-auto d-flex align-items-center">
                        <NavLink to="#" onClick={scrollToPricing} style={{ color: 'black', marginRight: '50px', fontSize: '40px' }}>
                        Pricing
                        </NavLink>

                        <NavLink to="/auth/login" style={{ color: 'black', marginRight: '50px', fontSize: '40px' }}>
                        Login
                        </NavLink>

                        <Button tag={Link} to="/auth/register" style={{ marginLeft: '10px', height: '100px', fontSize: '60px' }}>
                        Register
                        </Button>
                    </div>
                </Navbar>
            </Card>
        </Row>

{/************************************************* HEADLINE *************************************************/}

        <Row style={{ height: '800px', backgroundColor: "white", alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', marginBottom: '100px' }}>
            <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '6rem', textShadow: '1px 1px 0px #004aad' }}>
            <b>Your Lesson</b>
            </h1>
            <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '12rem', marginTop: '-1rem' }}>
            <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>AUTOMATED</i></b></div>
            </h1>
            <Button
            tag={Link}
            to="/auth/register"
            className="landing-btn-no-hover"
            style={{
                backgroundColor: 'rgb(11, 192, 223)',
                color: 'white',
                height: '100px',
                width: '500px',
                fontSize: '60px',
            }}
            >
            Get Started
            </Button>
        </div>
        {/* <img
            className='landing-page-macbook'
            src={macbook}
            alt="lesson-launchpad-on-macbook"
            style={{width: '90%', marginTop: '200px'}}
        /> */}
        </Row>

{/************************************************* MACBOOK IMAGE SPACING *************************************************/}

        {/* <Row style={{ height: '700px' }}>

        </Row> */}
        <LandingCarousel />
        <hr></hr>


{/************************************************* FEATURES *************************************************/}

    <div style={{ textAlign: 'center', marginBottom: '50px', marginTop: '100px' }}>    
        <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '6rem', textShadow: '1px 1px 0px #004aad' }}>
            <b>Features:</b>
        </h1>
    </div>
    <Container>
        <Row xs="1" className='my-5'>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, one: !initialHoverState.one })}
                >
                    <CardBody className='text-center'>
                        <img src={featureAutoAdvance} alt="auto-advance" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Auto Class Advance
                    </CardHeader>
                    {isHovered.one && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                        <CardText>
                            Lesson Launchpad will automatically transition to your next class when it's time. You can be free to take care of any tasks instead of fiddling with your computer and trying to get the next class's content on the screen.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, two: !initialHoverState.two })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureReminders} alt="auto-reminders" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Auto Reminders
                    </CardHeader>
                {isHovered.two && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                            Always forget to take attendance? Have a student that needs to get dismissed at a certain time? Schedule custom reminders that will automatically pop up on screen during class.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, three: !initialHoverState.three })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureCountdownTimers} alt="auto-timers" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Auto Timers
                    </CardHeader>
                {isHovered.three && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                        Schedule custom timers that will automatically appear to let your students know how much longer they have to complete a task. Band directors: students need to be in their seats by two minutes after the bell? Here's your solution.
                    </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, thirteen: !initialHoverState.thirteen })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureCountdownTimers} alt="auto-agenda" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Auto Agenda
                    </CardHeader>
                {isHovered.three && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                        Want an Agenda that keeps you on track? Schedule each part of your lesson and Lesson Launchpad will automatically keep you on track by showing a countdown for each part of your lesson.
                    </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, four: !initialHoverState.four })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureAnnouncement} alt="scrolling-marquee" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Scrolling Marquee
                    </CardHeader>
                {isHovered.four && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                        You know some students need to hear an announcement 100 times before they actually <emp>hear</emp> it. What if it was scrolling in front of them all class? 
                    </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, five: !initialHoverState.five })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureClock} alt="clock" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Clock
                    </CardHeader>
                {isHovered.five && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                            A simple clock to keep them and you on task. Don't want it? You can turn it off in settings.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, six: !initialHoverState.six })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureTextEditor} alt="advanced-text-editor" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Advanced Text Editor
                    </CardHeader>
                {isHovered.six && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                            Lesson Launchpad comes with an advanced text editor that allows you to format you lesson content in any way you want.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, seven: !initialHoverState.seven })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureTimer} alt="timer" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Timer
                    </CardHeader>
                {isHovered.seven && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                            In the middle of a lesson and decide you need a timer? With Lesson Launchpad, you'll always have one available one click away.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, eight: !initialHoverState.eight })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureStopwatch} alt="stopwatch" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Stopwatch
                    </CardHeader>
                {isHovered.eight && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                            In the middle of a lesson and decide you need a stopwatch? With Lesson Launchpad, you'll always have one available one click away.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, nine: !initialHoverState.nine })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureGoogleSlides} alt="google-slides-compatible" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Google Slides Compatible
                    </CardHeader>
                {isHovered.nine && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                            You can easily add all of your Google Slides presentations right into Lesson Launchpad so you can get all of the features of Lesson Launchpad without having to re-create all of your lesson content.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, fourteen: !initialHoverState.fourteen })}
                    >
                    <CardBody className='text-center'>
                        <img src={featureCanva} alt="canva-compatible" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Canva Compatible
                    </CardHeader>
                {isHovered.fourteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                            You can easily add all of your Canva projects right into Lesson Launchpad.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, ten: !initialHoverState.ten })}
                >
                    <CardBody className='text-center'>
                        <img src={featureBirthday} alt="google-slides-compatible" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Auto Happy Birthday
                    </CardHeader>
                {isHovered.ten && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                        Bad at remembering student's birthdays? Let Lesson Launchpad help you out. Lesson Launchpad will automatically pop-up a happy birthday message during class.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, eleven: !initialHoverState.eleven })}
                >
                    <CardBody className='text-center'>
                        <img src={featureRandomizer} alt="google-slides-compatible" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Randomizer
                    </CardHeader>
                {isHovered.eleven && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                        Pick a random student, put students into groups, randomize custom data.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, twelve: !initialHoverState.twelve })}
                >
                    <CardBody className='text-center'>
                        <img src={featurePrint} alt="print-lesson-plan" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Print Lesson Plan
                    </CardHeader>
                {isHovered.twelve && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                    <CardText>
                            Easily turn your Lesson Launchpad content into lesson plans to share with your administrators.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, fifteen: !initialHoverState.fifteen })}
                >
                    <CardBody className='text-center'>
                        <img src={featureGoogleDoc} alt="google-doc" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Google Doc Compatible
                    </CardHeader>
                {isHovered.fifteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                        <CardText>
                            Open and Edit Google Docs or Google Sheets right inside Lesson Launchpad.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, sixteen: !initialHoverState.sixteen })}
                >
                    <CardBody className='text-center'>
                        <img src={featureShare} alt="share" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Shareable
                    </CardHeader>
                {isHovered.sixteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                        <CardText>
                        Want to share your Lesson Launchpad content with parents or students through a link? You can do that with Lesson Launchpad.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, seventeen: !initialHoverState.seventeen })}
                >
                    <CardBody className='text-center'>
                        <img src={featurePowerPoint} alt="share" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        PowerPoint Compatible
                    </CardHeader>
                {isHovered.seventeen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                        <CardText>
                            You can easily add all of your PowerPoint presentations right into Lesson Launchpad so you can get all of the features of Lesson Launchpad without having to re-create all of your lesson content.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            <Col>
                <Card className='border' 
                    onClick={() => setIsHovered({ ...initialHoverState, eighteen: !initialHoverState.eighteen })}
                >
                    <CardBody className='text-center'>
                        <img src={featureShare} alt="share" style={{width: '200px', height: 'auto'}}/>
                    </CardBody>
                    <CardHeader className='text-center' id="landing-feature" style={{fontSize: '70px'}}>
                        Use PDF's
                    </CardHeader>
                {isHovered.eighteen && (
                    <CardImgOverlay style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '40px'}} >
                        <CardText>
                            Add your own PDF's to use as lesson content.
                        </CardText>
                    </CardImgOverlay>
                    )}
                </Card>
            </Col>
            
          
        </Row>
    </Container>

        {/* <Row style={{ justifyContent: 'center', fontFamily: 'Helvetica, Arial, sans-serif', height: '1900px' }}>
            <div className="d-flex flex-column" style={{  }}>
                <div className="align-self-center">
                <h1 style={{ color: 'rgb(11, 192, 223)', textShadow: '1px 1px 1px #004aad', fontSize: '120px'}}><b>Current Features</b></h1>
                <ul style={{ fontSize: '60px', marginLeft: '100px' }}>
                    <li>Scrolling Marquee</li>
                    <li>Advanced Text Editor</li>
                    <li>Clock</li>
                    <li>Google Slides Compatible</li>
                    <li>Timer</li>
                    <li>Stopwatch</li>
                    <li>Scheduled Reminders</li>
                    <li>Automation Features:</li>
                        <ul>
                            <li>Auto Class Change</li>
                            <li>Auto Countdown</li>
                        </ul>
                </ul>
                </div>
            </div>
            <div className="d-flex flex-column" style={{  }}>
                <div className="align-self-center">
                <h1 style={{ color: 'rgb(11, 192, 223)', textShadow: '1px 1px 1px #004aad', fontSize: '120px'}}><b>Coming Soon</b></h1>
                <ul style={{ fontSize: '60px' }}>
                    <li>Themes*</li>
                    <li>Flash Cards*</li>
                    <li>Random Student Picker*</li>
                    <li>Automation Features:</li>
                        <ul>
                            <li>Auto Happy Birthday*</li>
                            <li>Auto Music Between Classes*</li>
                        </ul>

                </ul>
                </div>
            </div>
        </Row> */}

{/************************************************* USE CASES *************************************************/}

{/* Carousel? */}


{/************************************************* PRICING *************************************************/}

<Row style={{ height: '1300px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
    <div ref={pricingRef} id="pricing-section" style={{ textAlign: 'center', marginBottom: '100px', marginLeft: '10%', marginRight: '10%', marginTop: '50px' }}>
        <h1 style={{ color: 'white', fontWeight: '1000', textShadow: '1px 1px 0px #004aad', fontSize: '80px' }}>
        <b><i> Cool! How much does it cost?</i></b>
        </h1>
        <h3 style={{ color: 'white', marginBottom: '5px', fontWeight: '600', fontSize: '60px'}} >
        Unlock the power of Lesson Launchpad at an unbeatable value:
        </h3>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        <div>Just $2.99 per month.</div>
        </h5>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        <div>Or enjoy a full year of access for only $29.99.</div>
        </h5>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        </h5>
        <h3 style={{ color: 'white', marginBottom: '5px', marginTop: '30px', fontWeight: '600', fontSize: '60px'}} >
        But wait, there's more:
        </h3>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        <div>Dive in risk-free with a 30-day full-featured trial.</div>
        </h5>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        <div>No credit card required until you're ready to take the plunge.</div>
        </h5>
        {/* <Button
        tag={Link}
        to="/auth/register"
        className="landing-btn-no-hover"
        style={{
            backgroundColor: 'white',
            color: 'rgb(11, 192, 223)',
            height: '50px',
            width: '200px',
            fontSize: '1.5rem',
        }}
        >
        Get Started
        </Button> */}
         <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: "rgb(11, 192, 223)", height: '150px', width: 'auto', fontSize: '50px', borderRadius: '30px', marginTop: '50px' }}>
            <div className="d-flex align-items-center">
            <img src={logo} alt="lesson-launchpad-logo" style={{ width: '125px', height: 'auto', marginRight: '15px' }} />
            Discover the possibilities with Lesson Launchpad today!
            </div>
        </Button>
    </div>
</Row>

        {/* 30 day full featured trial. No credit card required. Try anything you want, no obligations. */}


{/************************************************* DEMO *************************************************/}

        {/* <Row style={{minHeight: '2000px', backgroundColor: 'pink'}}>
            <div style={{ backgroundColor: 'red', height: '800px', width: '1600px', margin: '50px', padding: '10px'}}>
                <PresentDemoForLandingPage />
            </div>
        </Row> */}


{/************************************************* FOOTER *************************************************/}

        {/* <Row className="ml-auto" style={{ backgroundColor: "rgb(11, 192, 223)", height: '100px', justifyContent: 'center' }}>
        <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: "rgb(11, 192, 223)", height: '75px', width: 'auto', fontSize: '20px' }}>
            <div className="d-flex align-items-center">
            <img src={logo} alt="lesson-launchpad-logo" style={{ width: '50px', height: 'auto', marginRight: '15px' }} />
            Try Lesson Launchpad NOW
            </div>
        </Button>
        </Row>
 */}
     <Row style={{ height: '200px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
            <div style={{margin: '20px', color: 'white'}}>
                <NavLink to="/privacy" style={{color: 'white'}}><h1>Privacy Policy</h1></NavLink>
            </div>
            <img
                src={logoWhite}
                alt="lesson-launchpad-white-logo"
                style={{ height: '200px'}}
            />
            <div style={{margin: '20px', color: 'white'}}>
                <NavLink to="/contact" style={{color: 'white'}}><h1>Contact</h1></NavLink>
            </div>
    </Row>




    </div>
    )}
   </>
  )
}

export default LandingPage
