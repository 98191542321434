import React, {useState} from 'react'
import { selectUser } from "features/userSlice";
import { useSelector } from "react-redux";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import { Table, Input, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Row, Col } from "reactstrap";

function RosterTable({classId}) {

    const initialRosterData = {
        roster:  [
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
        ],
        descriptors: {
            descriptor1: "Descriptor 1",
            descriptor2: "Descriptor 2",
        }
    }

    const user = useSelector(selectUser);
    const classIndex = user.classes.findIndex((c) => c.classId === classId);
    const [editedRosterData, setEditedRosterData] = useState(user?.classes[classIndex]?.rosterData || initialRosterData);
    const [copyRosterToClassModal, setCopyRosterToClassModal] = useState(false);
    const [classesSelectedForRosterCopy, setClassesSelectedForRosterCopy] = useState([])
    const toggleCopyRosterToClassModal = () => {
        setCopyRosterToClassModal(!copyRosterToClassModal);
        setClassesSelectedForRosterCopy([])
    }

    const handleCheckboxChangeForRosterCopy = (classId) => {
        setClassesSelectedForRosterCopy(prev => {
          if (prev.includes(classId)) {
            // If classId is already selected, remove it
            return prev.filter(id => id !== classId);
          } else {
            // If classId is not selected, add it
            return [...prev, classId];
          }
        });
    };

    const copyRosterToClasses = async () => {
        try {
          const docRef = doc(db, "users", user.uid);
      
          // Make a deep copy of the user object
          const userCopy = JSON.parse(JSON.stringify(user));
      
          // Find the index of the class that has the roster to be copied
          const classIndex = userCopy.classes.findIndex((c) => c.classId === classId);
      
          if (classIndex === -1) {
            console.log(`Class with ID ${classId} not found.`);
            return;
          }
      
          // Get the roster to copy
          let rosterToCopy = userCopy.classes[classIndex].rosterData.roster;
      
          // Copy the roster to the selected classes
          let classesCopy = [...userCopy.classes];
      
          classesSelectedForRosterCopy.forEach((classId) => {
            const targetClassIndex = classesCopy.findIndex((c) => c.classId === classId);
      
            if (targetClassIndex !== -1) {
                if (classesCopy[targetClassIndex].rosterData === undefined) { //Some classes won't have rosterData Object if their roster hasn't been edited yet.
                    // Initialize the rosterData object and roster array
                    classesCopy[targetClassIndex].rosterData = {
                        roster: [...rosterToCopy].filter(
                        (rosterEntry) =>
                            rosterEntry.name !== "" ||
                            rosterEntry.descriptor1 !== "" ||
                            rosterEntry.descriptor2 !== "" ||
                            rosterEntry.birthday !== ""
                        ),
                        descriptors: {
                            descriptor1: "Descriptor 1",
                            descriptor2: "Descriptor 2",
                        }
                    };
                } else {
                    // Append the roster to the target class without overwriting
                    classesCopy[targetClassIndex].rosterData.roster = [
                        ...classesCopy[targetClassIndex].rosterData.roster, 
                        ...rosterToCopy
                    ].filter(
                        rosterEntry => rosterEntry.name !== "" || 
                                    rosterEntry.descriptor1 !== "" || 
                                    rosterEntry.descriptor2 !== "" || 
                                    rosterEntry.birthday !== ""
                    );
                }
            } else {
              console.log(`Class with ID ${classId} not found.`);
            }
          });
      
          // Update the user's classes with the modified data
          userCopy.classes = classesCopy;
      
          // Update the Firestore document with the modified user data
          await setDoc(docRef, userCopy);
      
          // Optionally close the modal
          toggleCopyRosterToClassModal();
      
        } catch (error) {
          console.log("Failed to copy roster", error);
        }
    };
      
      

    const saveRoster = async () => {
        try {
            const docRef = doc(db, "users", user.uid);
    
            const userCopy = JSON.parse(JSON.stringify(user));
    
            const classIndex = userCopy.classes.findIndex(
            (c) => c.classId === classId
            );
        
            userCopy.classes[classIndex].rosterData = editedRosterData;

            await setDoc(docRef, userCopy);

            setEditMode(false);
        } catch (error) {
            alert('Error updating document:', error);
        }
    }

    const handleRosterDataEdit = (e, index, field) => {
        const updatedData = { ...editedRosterData };
        updatedData.roster = [...updatedData.roster];
        updatedData.roster[index] = { ...updatedData.roster[index], [field]: e.target.value };
        setEditedRosterData(updatedData);
    };

    const handleRosterRowDelete = (index) => {
        const updatedData = { ...editedRosterData };
    
        // Create a new array by copying the existing roster array
        updatedData.roster = [...updatedData.roster];
    
        // Remove the item at the specified index
        updatedData.roster.splice(index, 1);
    
        // Update the state with the new roster array
        setEditedRosterData(updatedData);
    };

    const handleDescriptorChange = (e, field) => {
        const updatedData = { ...editedRosterData };
        updatedData.descriptors = { ...updatedData.descriptors };
        updatedData.descriptors[field] = e.target.value;
        setEditedRosterData(updatedData);
    }
    
    const handleAddRow = () => {
    const blankRow = { name: "", descriptor1: "", descriptor2: "", birthday: "" };
    setEditedRosterData((prev) => ({ ...prev, roster: [...prev.roster, blankRow] }));
    };

    const [editMode, setEditMode] = useState(false)
    const [editDescriptorMode, setEditDescriptorMode] = useState({descriptor1: false, descriptor2: false})

    return (
        <div>
            <Row>
                <Col md="8">
                    <Button onClick={() => !editMode ? setEditMode(!editMode) : saveRoster()}>{!editMode ? "Edit" : "Save"}</Button>
                    <Button onClick={handleAddRow}>Add Row</Button>
                </Col>
                <Col md="4">
                    {!editMode && <div onClick={toggleCopyRosterToClassModal} style={{textAlign: 'right', cursor: 'pointer', textDecoration: 'underline'}}>Copy Roster to another class?</div>}
                </Col>
            </Row>
            <Table>
                <thead>
                    <tr>
                        <th style={{width: '5%'}}>#</th>
                        <th style={{width: '25%'}}>Name</th>
                        <th style={{width: '25%'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                            {editDescriptorMode.descriptor1 === true ? (
                                <i className="fa fa-check" onClick={() => setEditDescriptorMode((prev) => ({...prev, descriptor1: false}))} style={{cursor: 'pointer', marginRight: '3px'}}/>
                            ) : (
                                <i className="fa fa-pencil" onClick={() => setEditDescriptorMode((prev) => ({...prev, descriptor1: true}))} style={{cursor: 'pointer', marginRight: '3px'}}/>
                            )}
                            {editDescriptorMode.descriptor1 === true ? (
                                        <>
                                        {" "}
                                        <Input
                                            type='text'
                                            name='descriptor1'
                                            // defaultValue={editedRosterData.descriptors.descriptor1}
                                            value={editedRosterData?.descriptors?.descriptor1}
                                            onChange={(e) => handleDescriptorChange(e, 'descriptor1')}
                                        />
                                        </>
                                    ) : (
                                        <>
                                        {editedRosterData?.descriptors?.descriptor1}
                                        </>
                                    )}
                            </div>
                        </th>
                        <th style={{width: '25%'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {editDescriptorMode.descriptor2 === true ? (
                                <i className="fa fa-check" onClick={() => setEditDescriptorMode((prev) => ({...prev, descriptor2: false}))} style={{cursor: 'pointer', marginRight: '3px'}}/>
                            ) : (
                                <i className="fa fa-pencil" onClick={() => setEditDescriptorMode((prev) => ({...prev, descriptor2: true}))} style={{cursor: 'pointer', marginRight: '3px'}}/>
                            )}
                            {editDescriptorMode.descriptor2 === true? (
                                        <>
                                        {" "}
                                        <Input
                                            type='text'
                                            name='descriptor2'
                                            // defaultValue={editedRosterData.descriptors.descriptor2}
                                            value={editedRosterData?.descriptors?.descriptor2}
                                            onChange={(e) => handleDescriptorChange(e, 'descriptor2')}
                                        />
                                        </>
                                    ) : (
                                        <>
                                        {editedRosterData?.descriptors?.descriptor2}
                                        </>
                                    )}
                            </div>
                        </th>
                        <th style={{width: '20%'}}>Birthday</th>
                    </tr>
                </thead>
                <tbody>
                    {editedRosterData?.roster?.map((data, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                                {editMode ? (
                                    <Input
                                        type='text'
                                        name='name'
                                        defaultValue={data.name}
                                        value={data.name}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'name')}
                                    />
                                ) : (
                                    <>
                                        {data.name}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Input
                                        type='text'
                                        name='descriptor1'
                                        defaultValue={data.descriptor1}
                                        value={data.descriptor1}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'descriptor1')}
                                    />
                                ) : (
                                    <>
                                        {data.descriptor1}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Input
                                        type='text'
                                        name='descriptor2'
                                        defaultValue={data.descriptor2}
                                        value={data.descriptor2}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'descriptor2')}
                                    />
                                ) : (
                                    <>
                                        {data.descriptor2}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Input
                                        type='date'
                                        name='birthday'
                                        defaultValue={data.birthday}
                                        value={data.birthday}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'birthday')}
                                    />
                                ) : (
                                    <>
                                        {data.birthday}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Button
                                        className="btn-icon btn-neutral"
                                        color="danger"
                                        id="tooltip808966390"
                                        size="md"
                                        type="button"
                                        onClick={() => handleRosterRowDelete(index)}
                                    >
                                        <i className="fa fa-trash" />
                                    </Button>
                                ) : (
                                    <>
                                        
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal scrollable isOpen={copyRosterToClassModal} toggle={toggleCopyRosterToClassModal} style={{width: '350px'}}>
                <ModalBody>
                <h5>Copy roster to:</h5>
                {user && user.classes && user.classes.filter(x => x.classId != classId).map(x => 
                    <FormGroup check>
                        <Label check>
                        <Input
                        type="checkbox"
                        defaultChecked={classesSelectedForRosterCopy.length > 0 && classesSelectedForRosterCopy.includes(x.classId)}
                        onChange={() => handleCheckboxChangeForRosterCopy(x.classId)}
                        />                
                        <span className="form-check-sign" />
                        {x.className}
                        </Label>
                    </FormGroup>
                )}
                </ModalBody>
                <ModalFooter>
                    <Button disabled={classesSelectedForRosterCopy.length < 1} onClick={() => copyRosterToClasses()}>Copy</Button>
                    <Button onClick={toggleCopyRosterToClassModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default RosterTable;
