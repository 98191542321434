import React, { useState, useEffect, useRef } from "react";
import ContentBox from "components/ContentBox";
import Clock from "components/Clock";
import Marquee from "react-fast-marquee";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { createRandomId } from "RandomId";
import {NavLink, useLocation} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { saveContentBoxTemplate } from "saveTemplate";
import GoogleSlidesBox from "/Users/michaeltucker/Documents/coding-projects/client/src/components/GoogleSlidesBox.js"
import "/Users/michaeltucker/Documents/coding-projects/client/src/assets/css/styles.css";
import TimerStopwatchContentBox from "components/TimerStopwatchContentBox";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/5.png";
import logoWithText from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-with-text.png";
import Timer from "components/Timer";
import Stopwatch from "components/Stopwatch";
import UseAutoCountdown from "AutoCountdown";
import UseAutoReminder from "AutoReminder";
import UseAutoNavigate from "AutoNavigate";
import UseAutoHappyBirthday from "AutoHappyBirthday";
import CountdownTimer from 'components/CountdownTimer.js';
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import PerfectScrollbar from "perfect-scrollbar";
import { Label, FormGroup, FormFeedback, Badge, Card, CardHeader, CardBody, Container, Input, CardTitle, CardImg, Row, Col, UncontrolledTooltip, ButtonDropdown, Button, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Dropdown, Modal, ModalBody, ModalHeader, CardText } from "reactstrap";
import ContentBoxesTable from "./tables/ContentBoxesTable";
import Whiteboard from "components/Whiteboard";
import Randomizer from "components/Randomizer";
import AgendaBox from "components/AgendaBox";
import ReactDatetime from "react-datetime";
import featureGoogleSlides from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-google-slides.svg";
import featureStopwatch from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-stopwatch.svg";
import featureTextEditor from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-text-editor.svg";
import featureTimer from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-timer.svg";
import featureRandomizer from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-randomizer.svg";
import featureAgenda from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-agenda.svg";
import featureMultiBox from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-multi-box.svg";
import featureWhiteboard from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-whiteboard.svg";
import featureCanva from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-canva.svg";
import featureCountdown from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-countdown.svg";
import featureCarousel from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-carousel.svg";
import featureGoogleDoc from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-google-doc.svg";
import featurePdfBox from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-pdf-box.svg";
import featurePowerPoint from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-power-point-box.svg";
import CanvaBox from "components/CanvaBox";
import ReorderContentBoxModal from "components/ReorderContentBoxModal";
import MultiBox from "components/MultiBox";
import CountdownBox from "components/CountdownBox";
import { EditorContent } from "@tiptap/react";
import GoogleDocSheetBox from "components/GoogleDocSheetBox";
import CarouselBox from "components/CarouselBox";
import PdfBox from "components/PdfBox";
import PowerpointBox from "components/PowerpointBox";

function Present( props ) { //props are set in the createLinks function of Sidebar.js

  const user = useSelector(selectUser);
  const location = useLocation()
  const share = location.pathname.includes('/share/');
  const shareUrl = share && location.pathname
  const partsFromShareUrl = share && shareUrl.split('/')
  const classIdFromShareUrl = share && partsFromShareUrl[partsFromShareUrl.length - 1];
  const classIdRef = useRef(props?.props?.classId || props.location?.state?.classId || null);
  // const classIdRef = useRef(() => share ? classIdFromShareUrl : props?.props?.classId || props.location?.state?.classId || null);
  const [classData, setClassData] = useState();
  const [boxes, setBoxes] = useState();
  const [announcements, setAnnouncements] = useState();
  const [heading, setHeading] = useState();
  const [show, setShow] = useState();
  const [isOpenArray, setIsOpenArray] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [view, setView] = useState();
  const [activeBoxes, setActiveBoxes] = useState() //This is related to the 'show' property, NOT the 'active' property
  const [classIndex, setClassIndex] = useState(user?.classes.findIndex((x) => x.classId === classIdRef.current));
  const [orderOfAnnouncements, setOrderOfAnnouncements] = useState();
  const [background, setBackground] = useState();
  const [backgroundAnnouncements, setBackgroundAnnouncements] = useState();
  const [backgroundClock, setBackgroundClock] = useState();
  const [showEditButton, setShowEditButton] = useState(false);
  const [showEditAnnouncementButton, setShowEditAnnouncementButton] = useState(false);
  const [clockVersion, setClockVersion] = useState();
  const [clockFontColor, setClockFontColor] = useState();
  const [clockLanguage, setClockLanguage] = useState();
  const [clockKey, setClockKey] = useState(0)
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const [showDatePickerContentBoxStorage, setShowDatePickerContentBoxStorage] = useState(false);
  const [noActiveBoxesAlert, setNoActiveBoxesAlert] = useState(false);
  const [noBoxesSetToShowAlert, setNoBoxesSetToShowAlert] = useState(false);
  const [navigationVisible, setNavigationVisible] = useState(true);
  // const [navbarMD, setNavbarMD] = useState({logo: '0', marquee: '8', clock: '2'});
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
  const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
  const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const suffix = ['', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'   ]
  const [date, setDate] = useState( x => {
    let dateObject = new Date()
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    return dateData;
  });
  const launchButtonModalData = [
    {
      image: featureTextEditor,
      title: "Text Editor Box",
      description: "A box with a text editor.",
    },
    {
      image: featureAgenda,
      title: "Agenda Box",
      description: "An automated Agenda."
    },
    {
      image: featureGoogleSlides,
      title: "Google Slides Box",
      description: "Use a google slides presentation."
    },
    {
      image: featureGoogleDoc,
      title: "Google Doc Box",
      description: "Show a google doc or sheet."
    },
    {
      image: featureCanva,
      title: "Canva Box",
      description: "Use a project from Canva."
    },
    {
      image: featureMultiBox,
      title: "MultiBox",
      description: "Two different boxes stacked vertically."
    },
    {
      image: featureStopwatch,
      title: "Timer/Stopwatch",
      description: "A box with a timer or stopwatch."
    },
    {
      image: featureCountdown,
      title: "Countdown Box",
      description: "Countdown the days to an event."
    },
    // {
    //   image: featureCarousel,
    //   title: "Carousel Box",
    //   description: "Flip through multiple boxes.",
    //   disabled: true
    // },
    {
      image: featurePdfBox,
      title: "PDF Box",
      description: "A box to show a PDF.",
    },
    {
      image: featurePowerPoint,
      title: "PowerPoint Box",
      beta: <Badge color="danger">Beta</Badge>,
      description: "A box to show a PowerPoint.",
    },
  ]

  const launchButtonWidgetData = [
    {
      image: featureRandomizer,
      title: "Randomizer",
      description: "Pick a random name."
    },
    {
      image: featureTimer,
      title: "Timer",
      description: "A timer."
    },
    {
      image: featureStopwatch,
      title: "Stopwatch",
      description: "A stowatch."
    },
    {
      image: featureWhiteboard,
      title: "Whiteboard",
      description: "A blank surface."
    },
  ]

  // Select the node/element you want to observe
  const targetNode = document.querySelector('.sidebar');

  // Options for the observer (what to observe)
  const config = { attributes: true, attributeFilter: ['class'] };

  // Callback function to execute when mutations are observed
  const callback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (mutation.target.className === 'sidebar-hidden') {
              setNavigationVisible(false);
            } else {
              setNavigationVisible(true);
            }
              // console.log('Class attribute changed:', mutation.target.className);
          }
      }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  if (targetNode) {
      observer.observe(targetNode, config);
  }

  useEffect(() => {
    if (user) {

      if (share) {
        classIdRef.current = classIdFromShareUrl
      } else {
        classIdRef.current = props?.props?.classId || props.location?.state?.classId || null;
      }

      let classData = user.classes.find((x) => x.classId === classIdRef.current);
      setClassData(classData);

      if (share) {
        let classIndexData = user?.classes.findIndex((x) => x.classId === classIdRef.current);
        setClassIndex(classIndexData);
      }

      const targetNode = document.querySelector('.sidebar');
      if (targetNode) {
        setNavigationVisible(true)
      } else {
        setNavigationVisible(false)
      }

      let viewData = user.classes.find(item => item.classId === classIdRef.current)?.view || 'row';
      setView(viewData);

      let announcementsData = user.classes[classIndex]?.orderOfAnnouncements;
      setOrderOfAnnouncements(announcementsData);

      let backgroundData = user.classes[classIndex]?.background || '';
      setBackground(backgroundData);

      let backgroundAnnouncementsData = user.classes[classIndex]?.backgroundAnnouncements || '';
      setBackgroundAnnouncements(backgroundAnnouncementsData);

      let backgroundClockData = user.classes[classIndex]?.backgroundClock || '';
      setBackgroundClock(backgroundClockData);

      let clockVersionData = user.classes[classIndex]?.clockVersion || null;
      setClockVersion(clockVersionData);

      let clockFontColorData = user.classes[classIndex]?.clockFontColor || null;
      setClockFontColor(clockFontColorData);

      let clockLanguageData = user.classes[classIndex]?.clockLanguage || null;
      setClockLanguage(clockLanguageData);

      setShow({
        marquee: user?.classes[classIndex]?.showMarquee || null,
        clock: user?.classes[classIndex]?.showClock || null,
      })
    }

  }, [user]);

  //perfect scrollbar for bottom content tabs navigation required for windows users
  useEffect(() => {
    let ps;

    if (navigator.platform.indexOf("Win") > -1) {
      const navbarTabsSection = document.querySelector('.present-navbar-tabs-section');

      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");

      if (navbarTabsSection) {
        // Initialize PerfectScrollbar on the .present-navbar-tabs-section element
        ps = new PerfectScrollbar(navbarTabsSection);
      }
    }

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        if (ps) {
          ps.destroy();
        }

        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []); // Removed mainPanel from dependencies since it's no longer used

  const handleDateForward = () => {
    let dateObject = new Date(date.year, date.month, date.day)
    let nextDay = new Date(dateObject.getTime() + (24 * 60 * 60 * 1000)); // Adds one day
    let dateData = {
      dayName: `${daysLong[nextDay.getDay()]}`,
      dateString: `${monthsLong[nextDay.getMonth()]} ${nextDay.getDate()}${suffix[nextDay.getDate()]}, ${nextDay.getFullYear()}`,
      year: nextDay.getFullYear(),
      month: nextDay.getMonth(),
      day: nextDay.getDate(),
      databaseFormattedDate: nextDay.toLocaleDateString('en-CA')
    }
    setDate(dateData)
  }

  const handleDateBackward = () => {
    let dateObject = new Date(date.year, date.month, date.day)
    let previousDay = new Date(dateObject.getTime() - (24 * 60 * 60 * 1000)); // Adds one day
    let dateData = {
      dayName: `${daysLong[previousDay.getDay()]}`,
      dateString: `${monthsLong[previousDay.getMonth()]} ${previousDay.getDate()}${suffix[previousDay.getDate()]}, ${previousDay.getFullYear()}`,
      year: previousDay.getFullYear(),
      month: previousDay.getMonth(),
      day: previousDay.getDate(),
      databaseFormattedDate: previousDay.toLocaleDateString('en-CA')
    }
    setDate(dateData)
  }

  const handleDateSet = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
    setShowDatePicker(!showDatePicker);
  }

  const handleDateSetContentBoxStorage = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
    setShowDatePickerContentBoxStorage(!showDatePickerContentBoxStorage);
  }

  const numberOfActiveBoxes = boxes && boxes.filter((box) => box.show === true).length

  useEffect(() => {
    if (boxes && boxes.length > 0) {
      const newArray = [];
      for (let i = 0; i < boxes.length; i++) {
        newArray.push(false);
      }
      setIsOpenArray(newArray);
      setActiveBoxes(boxes.filter(box => box.show === true).length)
    }
  }, [boxes]);

  const toggleDropdown = (index) => {
    const newIsOpenArray = [...isOpenArray];
    newIsOpenArray[index] = !newIsOpenArray[index];
    setIsOpenArray(newIsOpenArray);
  };

  const handleButtonToggle = (index) => {
    // Toggle the corresponding dropdown when a button is clicked
    toggleDropdown(index);
  };

  const [isLaunchButtonOpen, setIsLauncButtonOpen] = useState(false)
  
  // Helper function to compare arrays
  function arraysEqual(arr1, arr2) {
    if (arr1 != undefined && arr2 != undefined) {
      return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
    } else return false
  }

  const handleToggle = async (boxFromClick) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classIdRef.current
      );

      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes?.findIndex(
        (box) => box.contentBoxId === boxFromClick.contentBoxId
      );

      const copyOfBox = {...boxFromClick, show: !boxFromClick.show}

      // Update the content box in the document data
      userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = copyOfBox;

      // Update the document in Firestore
      await setDoc(docRef, userCopy); // Replace with the correct path to your document

      let newClockKey = clockKey
      setClockKey(++newClockKey);
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  //This is a fix for previous users contentBoxes not having the active property - 1/8/24
  const addActiveTagToOldContentBoxes = async () => {
      try {
        const docRef = doc(db, "users", user.uid);
  
        const userCopy = JSON.parse(JSON.stringify(user));

        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === classIdRef.current
        );
  
        // Update the content boxes with active = true
        userCopy.classes[classIndex].contentBoxes.map((x) => x.active = true)
  
        // Update the document in Firestore
        await setDoc(docRef, userCopy);

      } catch (error) {
        console.error('Error updating document:', error);
      }
  }

  const addContentBox = async (boxFromClick) => {
      try {
        const docRef = doc(db, "users", user.uid);
  
        const userCopy = JSON.parse(JSON.stringify(user));
    
        // Make a content box with a new empty contentBox or the box from the template.
        const contentBox = boxFromClick ? 
          {...boxFromClick,
            contentBoxId: createRandomId(),
            startDate: date.databaseFormattedDate,
            endDate: date.databaseFormattedDate,
            active: true
          }
        : 
          {
            contentBoxId: createRandomId(),
            startDate: date.databaseFormattedDate,
            endDate: date.databaseFormattedDate,
            heading: "Type here...",
            show: true,
            active: true
          };

        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === classIdRef.current
        );
  
        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes.push(contentBox);
  
        // Update the document in Firestore
        await setDoc(docRef, userCopy);

        toggleLaunchButtonModal()

        let newClockKey = clockKey
        setClockKey(++newClockKey);
      } catch (error) {
        console.error('Error updating document:', error);
      }
  }

  //announcments used to belong to a class in the data structure. This code was required to account for new data structure.
  const changeOldAnnouncementsToNewMapFormatV3 = async () => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Create an array to accumulate all announcements
      let allAnnouncements = [];
  
      // Iterate over each class in userCopy.classes
      if (userCopy.classes.length > 0) {
        userCopy.classes.forEach((currentClass, classIndex) => {
          // Change the announcements array from strings to maps.
          if (currentClass.announcements.length > 0) {
            const announcementsArrayOfMaps = currentClass.announcements.map((string) => ({
              html: string,
              text: string.replace(/<\/?[^>]+(>|$)|&nbsp;/g, ""),
              active: true,
              show: true,
              lastEdited: "",
              dateCreated: "",
              classIds: [currentClass.classId],
              id: createRandomId(),
            }));
  
            // Add the new orderOfAnnouncements array to the class
            userCopy.classes[classIndex].orderOfAnnouncements = announcementsArrayOfMaps.map(
              (announcement) => announcement.id
            );
  
            // Concatenate the announcements to the accumulator array
            allAnnouncements = allAnnouncements.concat(announcementsArrayOfMaps);
          }
        });
  
        // Delete the existing announcements array from userCopy.classes[classIndex]
        if (allAnnouncements.length > 0) {
          userCopy.classes.forEach((currentClass, classIndex) => {
            currentClass.announcements.splice(0, currentClass.announcements.length);
          });
        }
      }

      if (!Array.isArray(userCopy.announcements)) {
        userCopy.announcements = [];
      }  
      // Update the userCopy.announcements with the accumulated array
      userCopy.announcements = allAnnouncements;
  
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
  
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const [alert, setAlert] = useState(null);
  
  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const updateContentBox = async (contentBoxId, updatedContent) => {
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
    
        // Find the class index based on classId
        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === classIdRef.current
        );
    
        if (classIndex !== -1) {
          // Find the content box index based on contentBoxId
          const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
            (box) => box.contentBoxId === contentBoxId
          );
    
          if (contentBoxIndex !== -1) {
            // Make a copy of the existing content box using the spread operator
            let updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex] };
    
            // Update the content box with the new data
            // updatedContentBox.content = updatedContent.content;
            updatedContentBox = {...updatedContent}
            
            if (!updatedContentBox.userHeading) {
              if (heading.length > 9) {
                updatedContentBox.heading = heading.substring(0, 9) + "..."
              } else if (heading.length === 0) {
                updatedContentBox.heading = "Type here..."
              } else {
                updatedContentBox.heading = heading
              }
            }
    
            // Update the content box in the document data
            userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
    
            // Update the document in Firestore
            await setDoc(docRef, userCopy); // Replace with the correct path to your document
    
            // Update the local state with the updated document data
            setBoxes(userCopy.classes[classIndex].contentBoxes);
    
            successAlert()
          }
        }
      } catch (error) {
        console.error('Error updating document:', error);
      }
  };

  const addTemplateAlert = (contentBox) => {
    setAlert(
      <ReactBSAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="What would you like to call this template?"
        onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        btnSize=""
        defaultValue={contentBox.heading}
      />
    );
  };

  const addTemplateConfirmAlert = (e, contentBox) => {
    saveContentBoxTemplate(user, e, contentBox);
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
        title={
          <p>
            You entered: <b>{e}</b>
          </p>
        }
      />
    );
  };

  const options = [];
  for (let i = 1; i <= 60; i++) {
    options.push(<option key={i} value={i}>{`${i} Second${i !== 1 ? 's' : ''}`}</option>);
  }

  const addTimerStopwatchBoxChoiceRef = useRef();

  const toggleTimerStopwatchAlert = () => {
    setAlert(
      <ReactBSAlert
        showCancel
        title="Choose type"
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => {
          const choice = addTimerStopwatchBoxChoiceRef.current.value;
          if (choice === "timer") {
            addContentBox({
              contentBoxId: createRandomId(),
              heading: 'Timer',
              show: true,
              userHeading: true,
              timer: true,
              active: true
            });
          } else if (choice === "stopwatch") {
            addContentBox({
              contentBoxId: createRandomId(),
              heading: 'Stopwatch',
              show: true,
              userHeading: true,
              stopwatch: true,
              active: true
            });
          } else if (choice === "both") {
            addContentBox({
              contentBoxId: createRandomId(),
              heading: 'Timer/Stopw...',
              show: true,
              userHeading: true,
              timer: true,
              stopwatch: true,
              active: true
            });
          }
          hideAlert();
          toggleLaunchButtonModal();
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        btnSize=""
      >
        <div>
          <Input
            id="timerStopwatchSelect"
            name="select"
            type="select"
            onChange={(e) => addTimerStopwatchBoxChoiceRef.current = e.target}
            required={true}
            defaultValue={"none"}
          >
            <option disabled value="none">Pick one...</option>
            <option value="timer">Timer</option>
            <option value="stopwatch">Stopwatch</option>
            <option value="both">Both</option>
          </Input>
        </div>
      </ReactBSAlert>
    );
  }

  const deleteTemplateAlert = (index) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => deleteTemplate(index)}
        onCancel={() => cancelTemplateDelete()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this content box!
      </ReactBSAlert>
    );
  };

  const successTemplateDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your template has been deleted.
      </ReactBSAlert>
    );
  };

  const cancelTemplateDelete = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your template is safe :)
      </ReactBSAlert>
    );
  };

  const deleteTemplate = async (index) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Remove the template at the specified index
      userCopy.contentBoxTemplates.splice(index, 1);
  
      // Update the document in Firestore
      await setDoc(docRef, userCopy);

      successTemplateDelete()
  
      console.log('Content box deleted successfully!');
    } catch (error) {
      console.error('Error deleting content box:', error);
    }
  }

  const [navbarMD, setNavbarMD] = useState({logo: '0', marquee: '8', clock: '2'});

  useEffect(() => {
    if (user && show && user.settings) {
      if (!user.settings.logo && show?.marquee && show?.clock) setNavbarMD({logo: '0', marquee: '10', clock: '2'})
      if (!user.settings.logo && show?.marquee && !show?.clock) setNavbarMD({logo: '0', marquee: '12', clock: '0'})
      if (!user.settings.logo && !show?.marquee && show?.clock) setNavbarMD({logo: '0', marquee: '0', clock: '12'})
      if (!user.settings.logo && !show?.marquee && !show?.clock) setNavbarMD({logo: '0', marquee: '0', clock: '0'})
      if (user.settings.logo && show?.marquee && show?.clock) setNavbarMD({logo: '0', marquee: '10', clock: '2'})
      if (user.settings.logo && show?.marquee && !show?.clock) setNavbarMD({logo: '0', marquee: '12', clock: '0'})
      if (user.settings.logo && !show?.marquee && show?.clock) setNavbarMD({logo: '0', marquee: '0', clock: '12'})
      if (user.settings.logo && !show?.marquee && !show?.clock) setNavbarMD({logo: '0', marquee: '0', clock: '0'})
    } else if (user && show) {
      if (show.marquee && show.clock) setNavbarMD({logo: '0', marquee: '10', clock: '2'})
      if (show.marquee && !show.clock) setNavbarMD({logo: '0', marquee: '12', clock: '0'})
      if (!show.marquee && show.clock) setNavbarMD({logo: '0', marquee: '0', clock: '12'})
      if (!show.marquee && !show.clock) setNavbarMD({logo: '0', marquee: '0', clock: '0'})
    }
  }, [user, show]);

  const [modal, setModal] = useState(false);
  const [toolType, setToolType] = useState(); // State to track which tool to display
  const TOOL_TYPE = {
    TIMER: 'TIMER',
    STOPWATCH: 'STOPWATCH',
    WHITEBOARD: 'WHITEBOARD',
    RANDOMIZER: 'RANDOMIZER'
  }

  const toggle = () => {
    setModal(!modal);
  };

  const openToolModal = (toolType) => {
    setToolType(toolType); // Set the tool type to display
    toggle(); // Open the modal
  };

  const [clockVersionModal, setClockVersionModal] = useState(false)

  const toggleClockVersionModal = () => {
    setClockVersionModal(!clockVersionModal)
  }

  const [selectedClockFaceOptionCard, setSelectedClockFaceOptionCard] = useState(null);
  const [hoveredClockFaceOptionCard, setHoveredClockFaceOptionCard] = useState(null);

  const handleMouseOverClockFaceOption = (index) => {
    setHoveredClockFaceOptionCard(index);
  };

  const handleMouseLeaveClockFaceOption = () => {
    setHoveredClockFaceOptionCard(null);
  };

  const handleClickClockFaceOption = (index) => {
    setSelectedClockFaceOptionCard(index);
    setClockVersion(index+1)
  };

  const handleClockFontColorChange = (e) => {
    setClockFontColor(e.target.value)
  }

  const handleClockLanguageChange = (e) => {
    setClockLanguage(e.target.value)
  }

  const saveClockVersion = async () => {
    try {
      const docRef = doc(db, "users", user.uid);

      const userCopy = JSON.parse(JSON.stringify(user));

      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classIdRef.current
      );

      userCopy.classes[classIndex].clockVersion = clockVersion;
      userCopy.classes[classIndex].clockFontColor = clockFontColor;
      userCopy.classes[classIndex].clockLanguage = clockLanguage;

      // Update the document in Firestore
      await setDoc(docRef, userCopy);
      toggleClockVersionModal();

    } catch (error) {
      console.error('Error updating document:', error);
    }
  }

  const [backgroundChangeElement, setBackgroundChangeElement] = useState('Background');

  const [backgroundModal, setBackgroundModal] = useState(false);

  const toggleBackgroundModal = () => {
    setBackgroundModal(!backgroundModal);
    saveBackground()
  };

  const saveBackground = async () => {
    try {
      const docRef = doc(db, "users", user.uid);

      const userCopy = JSON.parse(JSON.stringify(user));

      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classIdRef.current
      );

      userCopy.classes[classIndex].background = background;
      userCopy.classes[classIndex].backgroundAnnouncements = backgroundAnnouncements;
      userCopy.classes[classIndex].backgroundClock = backgroundClock;

      // Update the document in Firestore
      await setDoc(docRef, userCopy);

    } catch (error) {
      console.error('Error updating document:', error);
    }
  }

  const [hoveredContentBoxModalOption, setHoveredContentBoxModalOption] = useState(null);

  const handleMouseOverContentBoxModalOption= (index) => {
    setHoveredContentBoxModalOption(index);
  };

  const handleMouseLeaveContentBoxModalOption = () => {
    setHoveredContentBoxModalOption(null);
  };

  const [hoveredWidgetModalOption, setHoveredWidgetModalOption] = useState(null);

  const handleMouseOverWidgetModalOption= (index) => {
    setHoveredWidgetModalOption(index);
  };

  const handleMouseLeaveWidgetModalOption = () => {
    setHoveredWidgetModalOption(null);
  };

  const [hoveredTemplateModalOption, setHoveredTemplateModalOption] = useState(null);

  const handleMouseOverTemplateModalOption= (index) => {
    setHoveredTemplateModalOption(index);
  };

  const handleMouseLeaveTemplateModalOption = () => {
    setHoveredTemplateModalOption(null);
  };

  const handleMoveLeft = (contentBoxId, contentBoxToLeftId) => {
    updateContentBoxOrder(contentBoxId, contentBoxToLeftId, 'left');
  };
  
  const handleMoveRight = (contentBoxId, contentBoxToRightId) => {
    updateContentBoxOrder(contentBoxId, contentBoxToRightId, 'right');
  };
  
  const updateContentBoxOrder = async (contentBoxId, targetBoxId, direction) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      // Ensure a deep copy of the user object
      const userCopy = JSON.parse(JSON.stringify(user));
  
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classIdRef.current
      );
  
      if (classIndex !== -1) {
        const contentBoxes = userCopy.classes[classIndex].contentBoxes;
        const currentIndex = contentBoxes.findIndex(box => box.contentBoxId === contentBoxId);
        const targetIndex = contentBoxes.findIndex(box => box.contentBoxId === targetBoxId);
  
        if (currentIndex === -1 || targetIndex === -1) {
          console.error('Content box not found');
          return;
        }

        let newIndex;
        if (direction === 'left') {
          newIndex = targetIndex;
          if (currentIndex < newIndex) {
            newIndex--; // Adjust index for correct insertion
          }
        } else if (direction === 'right') {
          newIndex = targetIndex + 1;
          if (currentIndex < newIndex) {
            newIndex--; // Adjust index for correct insertion
          }
        }
  
        if (newIndex < 0 || newIndex >= contentBoxes.length) {
          return; // Invalid move
        }
  
        const [movedBox] = contentBoxes.splice(currentIndex, 1);
        contentBoxes.splice(newIndex, 0, movedBox);
  
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
      } else {
        console.error('Class not found in user data');
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
    let newClockKey = clockKey
    setClockKey(++newClockKey); //This code is to prevent Clock and automations from stopping.
  };
  
  const [storageModal, setStorageModal] = useState(false);

  const toggleStorageModal = () => {
    hideAlert()
    setStorageModal(!storageModal)
  }

  const [launchButtonModal, setLaunchButtonModal] = useState(false);

  const toggleLaunchButtonModal = () => {
    setLaunchButtonModal(!launchButtonModal)
    setCurrentIndexLaunchModalContentBoxes(0)
    setCurrentIndexLaunchModalTemplates(0)
    let newClockKey = clockKey
    setClockKey(++newClockKey); //This code is to prevent Clock and automations from stopping.
  }

  const [currentIndexLaunchModalContentBoxes, setCurrentIndexLaunchModalContentBoxes] = useState(0);
  const visibleCards = 5;
  const totalCards = launchButtonModalData.length;

  const handleNextLaunchModalContentBoxes = () => {
      if (currentIndexLaunchModalContentBoxes < (totalCards +1) - visibleCards) {
          setCurrentIndexLaunchModalContentBoxes(currentIndexLaunchModalContentBoxes + 1);
      }
  };

  const handlePrevLaunchModalContentBoxes = () => {
      if (currentIndexLaunchModalContentBoxes > 0) {
          setCurrentIndexLaunchModalContentBoxes(currentIndexLaunchModalContentBoxes - 1);
      }
  };

  const [currentIndexLaunchModalTemplates, setCurrentIndexLaunchModalTemplates] = useState(0);
  const visibleCardsTemplates = 5;
  const totalCardsTemplates = templates?.length;

  const handleNextLaunchModalTemplates = () => {
      if (currentIndexLaunchModalTemplates < (totalCardsTemplates +1) - visibleCardsTemplates) {
        setCurrentIndexLaunchModalTemplates(currentIndexLaunchModalTemplates + 1);
      }
  };

  const handlePrevLaunchModalTemplates = () => {
      if (currentIndexLaunchModalTemplates > 0) {
        setCurrentIndexLaunchModalTemplates(currentIndexLaunchModalTemplates - 1);
      }
  };

  const [openWidgetModal, setOpenWidgetModal] = useState(false);
  const toggleOpenWidgetModal = () => {
    setOpenWidgetModal(!openWidgetModal)
  }

  const demoCountdownTimerProps = {
    duration: 20,
    header: "You must be in your seat by:",
    alertMessage: "Times Up!",
    alertSound: "short",
    youtube: false,
    youtubeSrc: "",
    active: true,
  }

  const [demoCountdownTimerModal, setDemoCountdownTimerModal] = useState()

  const toggleDemoCountdownTimerModal = () => {
    setDemoCountdownTimerModal(!demoCountdownTimerModal)
  }

  const darkMode = false;

  const isMobile = false // /Mobi/.test(navigator.userAgent);

  const [multiBoxChoices, setMultiBoxChoices] = useState();

  const [multiBoxSelectModal, setMultiBoxSelectModal] = useState();

  const toggleMultiBoxSelectModal = () => {
    setMultiBoxSelectModal(!multiBoxSelectModal)
  }

  const handleAddMultiBox = () => {

    if (multiBoxChoices.box1 !== undefined && multiBoxChoices.box2 !== undefined) {
      let firstBox = {}
      if (multiBoxChoices.box1.startsWith("id")) {
        const template = templates.find(x => x.template.contentBoxId === multiBoxChoices.box1)
        firstBox = {...template.template, contentBoxId: createRandomId()}
      } else {
        firstBox = {
          active: true,
          show: true,
          content: null, // content should be null for all options
          [multiBoxChoices.box1]: true,
          contentBoxId: createRandomId(),
        }
      }
      
      let secondBox = {}
      if (multiBoxChoices.box2.startsWith("id")) {
        const template = templates.find(x => x.template.contentBoxId === multiBoxChoices.box2)
        secondBox = {...template.template, contentBoxId: createRandomId()}
      } else {
        secondBox = {
          active: true,
          show: true,
          content: null, // content should be null for all options
          [multiBoxChoices.box2]: true,
          contentBoxId: createRandomId(),
        }
      }

      const boxToAdd = {
        multiBox: true,
        active: true,
        show: true,
        startDate: date.databaseFormattedDate,
        endDate: date.databaseFormattedDate,
        content: [firstBox, secondBox],
        heading: 'MultiBox'
      }
      addContentBox(boxToAdd)
      toggleMultiBoxSelectModal()
      toggleLaunchButtonModal()
    } else {
      window.alert('Must select two box choices...')
    }
  }

  //Code to prevent screen from timing out.
  if ('wakeLock' in navigator && user?.settings && user?.settings.screenTimeout !== '0') {
    let wakeLock = null;
    const wakeLockTimeout = (user.settings?.screenTimeout || 60) * 60 * 1000; // 45 minutes in milliseconds
  
    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request('screen');
  
        wakeLock.addEventListener('release', () => {
          console.log('Wake lock released');
        });
  
        // When the document becomes hidden, release the lock
        document.addEventListener('visibilitychange', async () => {
          if (wakeLock !== null && document.visibilityState === 'hidden') {
            await wakeLock.release();
            wakeLock = null;
          }
        });
  
        // Set timeout to release the wake lock after 45 minutes
        setTimeout(async () => {
          if (wakeLock !== null) {
            await wakeLock.release();
            wakeLock = null;
            console.log(`Wake lock released after ${wakeLockTimeout} minutes`);
          }
        }, wakeLockTimeout);
      } catch (e) {
        console.error(`${e.name}, ${e.message}`);
      }
    };
  
    // Request Wake Lock
    requestWakeLock();
  }
  
  //Had to move useEffect here after doing react-app-rewired and installing related packages. Used to have quite a few methods in dependency array, but it was causing infinite reloads.
  useEffect(() => {
    if (user) {
      if (classData) {

        //This is a fix to convert old Annoucements array to an Array of Maps - 1/10/24
        if (classData.announcements && classData.announcements.every(item => typeof item === 'string') && classData.announcements.length > 0) {
          console.log("Changing Strings to Maps...")
          changeOldAnnouncementsToNewMapFormatV3()
        }

        let activeContentBoxes;

        //The first part of this is a fix for previous users contentBoxes not having the active property - 1/8/24
        if (classData.contentBoxes.filter((x) => x.active === true).length === 0 && classData.contentBoxes.filter((x) => x.active === false).length === 0) {
          addActiveTagToOldContentBoxes()
        } else {
          if (user.settings?.useActiveDates) {
            activeContentBoxes = classData.contentBoxes.filter((box) => {
              if (box.startDate === undefined) {
                return true;
              } else {
                const [startYear, startMonth, startDay] = box.startDate?.split('-');
                const [endYear, endMonth, endDay] = box.endDate?.split('-');
                const startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
                const endDate = new Date(endYear, endMonth - 1, endDay, 0, 0, 0, 0);
                const dayToCompare = new Date(date.year, date.month, date.day, 0, 0, 0, 0)
                return startDate <= dayToCompare && endDate >= dayToCompare;
              }
            });
          } else {
            activeContentBoxes = classData.contentBoxes.filter((x) => x.active === true);
          }
          // Compare the new state with the current state before updating
          if (!arraysEqual(boxes, activeContentBoxes)) {
            setBoxes(activeContentBoxes);
          } 
        }

        if (!activeContentBoxes || activeContentBoxes.length === 0) {
          setNoActiveBoxesAlert(true);
        } else {
          let noBoxesSetToShow = true;
          for (let box of activeContentBoxes) {
            if (box.show === true) {
              noBoxesSetToShow = false;
              break; // No need to continue once we found a box that should show
            }
          }
          setNoBoxesSetToShowAlert(noBoxesSetToShow);
        }        

        //New Announcements filtering.
        if (user.announcements) {
          // Filter announcements based on classIds
          const filteredAnnouncements = user.announcements.filter((announcement) =>
            announcement.classIds.includes(classData.classId)
          );

          let activeAnnouncements;

          if (user.settings?.useActiveDates === true) {
            activeAnnouncements = filteredAnnouncements.filter((announcement) => {
              const startDate = new Date(announcement.startDate);
              startDate.setHours(0, 0, 0, 1); // Normalize start date
              const endDate = new Date(announcement.endDate);
              endDate.setHours(24); // Adjust end date to include the whole day
              const dayToCompare = new Date(date.year, date.month, date.day)
              dayToCompare.setHours(0,0,0,0)
              return startDate <= dayToCompare && endDate >= dayToCompare || announcement.startDate === undefined;
            });
          } else {
            activeAnnouncements = filteredAnnouncements.filter((announcement) => announcement.active === true);
          }
      
          // Sort filtered announcements based on orderOfAnnouncements
          const sortedAnnouncements = activeAnnouncements.sort((a, b) => {
            const indexA = orderOfAnnouncements?.indexOf(a?.id);
            const indexB = orderOfAnnouncements?.indexOf(b?.id);
      
            return indexA - indexB;
          });
      
          // Modify and flatten the sorted announcements
          const newAnnouncements = sortedAnnouncements.flatMap((announcement) => [
            { ...announcement },
            { ...announcement, html: "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>" }
          ]);
      
          setAnnouncements(newAnnouncements);
        }
      }
      setTemplates(user?.contentBoxTemplates);
      setLoadComplete(true);
    }
  }, [user, boxes, date, classData]);

  return (
    <div className="content" style={{marginTop: '0px', backgroundImage: background }}>
      { user && user.settings && !user.settings?.alteredSchedule ? (
        <>
           <UseAutoNavigate clockKey={clockKey}/>
           <UseAutoCountdown clockKey={clockKey}/>
           <UseAutoReminder clockKey={clockKey}/>
           {classData && classData.autoHappyBirthday?.active && <UseAutoHappyBirthday clockKey={clockKey} classId={classIdRef.current} classData={classData}/>}
        </>
      ): null}

{/**************************************************** ANNNOUNCEMENTS AND CLOCK *****************************************************/}

    {user ?
      <>
       <Row style={{paddingTop: '12px'}}>
       {alert}
        {navbarMD && navbarMD.logo > 0 ?
        <Col md={navbarMD.logo} className="logo-col">
          <Card style={{height: '95px'}}>
            <CardHeader style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <CardTitle tag="h2">
                <img src={logoWithText} alt="react-logo" style={{ width: 'auto', height: '68px', marginTop: '-12px' }} />
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
        : null}

        {navbarMD && navbarMD.marquee > 0 ?
        <Col md={navbarMD.marquee} style={{paddingLeft: '6px'}}>
          <Card className="marquee-card" style={{backgroundImage: backgroundAnnouncements}} onMouseEnter={() => setShowEditAnnouncementButton(true)} onMouseLeave={() => setShowEditAnnouncementButton(false)}>
            {showEditAnnouncementButton && !location.pathname.includes('/share/') && (
                <>
                  <Button
                    id="EditAnnouncements"
                    // className="btn-round btn-icon"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-10px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px'}}
                    size="sm"
                  >
                    <NavLink 
                      to={{
                          pathname: '/announcement-form',
                          state: { classId: classIdRef.current, announcements: announcements, path: props.location} //MAY NEED TO CHANGE THIS AFTER FIXING REDIRECT ISSUE
                      }}
                      style={{textDecoration: 'none'}}
                    >
                      <i className="fa fa-pencil" style={{color: 'white'}}/>
                    </NavLink>
                  </Button>
                  <UncontrolledTooltip
                  delay={0}
                  target="EditAnnouncements"
                  placement="top"
                  >
                    Click to edit
                  </UncontrolledTooltip>
                </>
              )}
            <CardHeader style={{padding: '7px 15px 0px'}}>
              <CardTitle tag="h1" style={{cursor: 'default'}}>
                <Marquee speed={user && user.settings && user.settings?.marqueeSpeed ? user.settings?.marqueeSpeed : '125'} gradientWidth="0" style={{overflow: 'hidden'}}>
                {announcements && announcements.map((announcement, index) => (
                    
                    <div
                      key={`023ebadoibas01-${index}`}
                      // style={{ fontSize: '30px', lineHeight: '1'}}
                    >
                    {ReactHtmlParser(announcement.html)}
                    </div>
                ))}
                </Marquee>
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
        : null}

        {navbarMD && navbarMD.clock > 0 ?
        <Col md={navbarMD.clock} className="clock-col" style={{paddingRight: '6px'}}>
          <Card className="clock-card" style={{backgroundImage: backgroundClock}} onMouseEnter={() => setShowEditButton(!showEditButton)} onMouseLeave={() => setShowEditButton(!showEditButton)}> 
            {showEditButton && !location.pathname.includes('/share/') && (
              <Button
                // className="btn-round btn-icon"
                // id="editClockVersion"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-10px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px'}}
                size="sm"
                onClick={toggleClockVersionModal}
              >
                <i className="fa fa-pencil" />
              </Button>
            )}
             {/* <UncontrolledTooltip
              delay={0}
              target="editClockVersion"
              placement="top"
              >
                Click to edit
            </UncontrolledTooltip> */}
            <CardHeader style={{padding: '0px 0px 0px', cursor: 'default'}}>
              {show && show.clock &&
                <Clock show={show} showSeconds={user && user.settings && user.settings?.showSeconds} clockVersion={clockVersion || selectedClockFaceOptionCard+1} clockFontColor={clockFontColor} clockLanguage={clockLanguage} clockKey={clockKey}/>
              }
            </CardHeader>
          </Card>
          <Modal
            isOpen={clockVersionModal}
            toggle={toggleClockVersionModal}
            backdrop={true}
            scrollable
            style={{ padding: '20px' }}
          >
            <ModalHeader toggle={toggleClockVersionModal}>Set Clock Version</ModalHeader>
            <ModalBody>
              <Container>
                <Row xs="2">
                  {[1, 2, 3, 4, 5].map((version, index) => (
                    <Card
                      key={`23jaijkasdf023kd-${version}-${index}`}
                      style={{
                        height: '95px',
                        border: selectedClockFaceOptionCard === index ? '2px solid blue' : (hoveredClockFaceOptionCard === index ? '1px solid blue' : 'none'),
                        backgroundImage: backgroundClock,
                      }}
                      onMouseOver={() => handleMouseOverClockFaceOption(index)}
                      onMouseLeave={handleMouseLeaveClockFaceOption}
                      onClick={() => handleClickClockFaceOption(index)}
                    >
                      <CardHeader style={{ padding: '0px 0px 0px' }}>
                        <Clock clockVersion={version} clockFontColor={clockFontColor} showSeconds={user && user.settings && user.settings?.showSeconds} clockLanguage={clockLanguage}/>
                      </CardHeader>
                    </Card>
                  ))}
                </Row>
              </Container>
              <Row>
                {/* <FormGroup> */}
                <Col>
                  <Label for="exampleColor">
                    Font Color
                  </Label>
                  <Input
                    id="exampleColor"
                    name="color"
                    placeholder="color placeholder"
                    type="color"
                    value={clockFontColor}
                    style={{height: '50px'}}
                    onChange={(e) => handleClockFontColorChange(e)}
                  />
                </Col>
                <Col>
                  <Label for="language">
                    Language
                  </Label>
                  <Input
                    id="language"
                    name="language"
                    placeholder="language"
                    type="select"
                    value={clockLanguage}
                    style={{height: '50px'}}
                    onChange={(e) => handleClockLanguageChange(e)}
                  >
                    <option>English</option>
                    <option>French</option>
                  </Input>
                </Col>
                {/* </FormGroup> */}
              </Row>
            </ModalBody>
            <Button onClick={saveClockVersion}>Save Clock Version</Button>
          </Modal>
        </Col>
        : null}

    </Row>


{/**************************************************** CONTENT BOXES *****************************************************/}

          <Modal
            isOpen={multiBoxSelectModal}
            toggle={toggleMultiBoxSelectModal}
            backdrop={true}
            scrollable
            style={{ padding: '20px' }}
          >
            <ModalHeader toggle={toggleMultiBoxSelectModal}>MultiBox Options</ModalHeader>
            <ModalBody>
              <Row xs='2'>
                <FormGroup>
                  <Label for="box1">
                    Box 1 Choice
                  </Label>
                  <Input
                    id="box1"
                    name="box1"
                    placeholder="color placeholder"
                    type="select"
                    value={null}
                    style={{height: '40px'}}
                    onChange={(e) => setMultiBoxChoices({...multiBoxChoices, box1: e.target.value})}
                    defaultValue={'none'}
                  >
                    <option disabled value="none">Pick one...</option>
                    <option value="textEditor">Text Editor Box</option>
                    <option value="slideshow">Google Slides Box</option>
                    <option value="googleDocSheet">Google Doc Box</option>
                    <option value="canva">Canva Box</option>
                    <option value="timer">Timer Box</option>
                    <option value="stopwatch">Stopwatch Box</option>
                    <option value="countdown">Countdown Box</option>
                    <option value="pdf">PDF Box</option>
                    <option value="powerPoint">PowerPoint Box</option>
                    <hr></hr>
                    <optgroup label="Your Templates">
                      {templates && templates.map((box) => <option value={box.template.contentBoxId}>{box.name}</option> )}
                    </optgroup>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="box2">
                    Box 2 Choice
                  </Label>
                  <Input
                    id="box2"
                    name="box2"
                    placeholder="Box 2"
                    type="select"
                    value={null}
                    style={{height: '40px'}}
                    onChange={(e) => setMultiBoxChoices({...multiBoxChoices, box2: e.target.value})}
                    defaultValue={'none'}
                  >
                    <option disabled value="none">Pick one...</option>
                    <option value="textEditor">Text Editor Box</option>
                    <option value="slideshow">Google Slides Box</option>
                    <option value="googleDocSheet">Google Doc Box</option>
                    <option value="canva">Canva Box</option>
                    <option value="timer">Timer Box</option>
                    <option value="stopwatch">Stopwatch Box</option>
                    <option value="countdown">Countdown Box</option>
                    <option value="pdf">PDF Box</option>
                    <option value="powerPoint">PowerPoint Box</option>
                    <hr></hr>
                    <optgroup label="Your Templates">
                      {templates && templates.map((box) => <option value={box.template.contentBoxId}>{box.name}</option> )}
                    </optgroup>
                  </Input>
                </FormGroup>

              </Row>
            </ModalBody>
            <Button onClick={handleAddMultiBox}>Add MultiBox</Button>
          </Modal>


          
    <div className="present-style-row" style={{top: navbarMD.clock === '0' && navbarMD.marquee === '0' ? '15px' : '120px'}}>
    {noBoxesSetToShowAlert &&
      <Row style={{ flex: 1, alignContent: "center" }}>
        <Col
          md={{
            offset: 3,
            size: 6
          }}
          sm="12"
        >
          <Card body className="text-center" style={{ marginBottom: '0px', border: '1px solid red'}}>
            <CardHeader className="h2" style={{marginBottom: '0px'}}>NO BOXES SET TO SHOW</CardHeader>
            <CardBody>
              {/* <div>
                If you haven't added content to this class previously, you can get started by clicking the "Launch" button below.
              </div>
              <br></br> */}
              <div className="h6">
                {/* To create content for this date <span onClick={toggleLaunchButtonModal} style={{textDecoration: 'underline', cursor: 'pointer'}}>CLICK HERE</span>. */}
                CLICK ONE OF YOUR CONTENT BOX TABS BELOW TO TOGGLE IT TO "SHOW"
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    }
    {loadComplete && boxes && boxes.length ? (
      <Row style={{ flex: 1 }}>
      <PanelGroup direction="horizontal" autoSaveId={`panelSaveId-${classIdRef.current}`} style={{overflow: 'visible'}}>
      {boxes && boxes.filter(box => box.show === true).map((box, index) => 
          <>
            <Panel 
              defaultSize={30}
              minSize={25}
              maxSize={75}
              style={{overflow: 'visible', position: 'relative'}}
              key={`panel-${classIdRef.current}-${index}`}
            >
              {box === undefined ?
                <></>
              : box.slideshow ?
                <>
                    <GoogleSlidesBox
                      key={`googleSlidesBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      view={view}
                      user={user}
                      classId={classIdRef.current}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
              : box.googleDocSheet ?
              <>
                  <GoogleDocSheetBox
                    key={`googleDocBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                    content={box}
                    navbarMD={navbarMD}
                    view={view}
                    user={user}
                    classId={classIdRef.current}
                    multiBox={false}
                    updateContentBox={updateContentBox}
                    navigationVisible={navigationVisible}
                  />
              </>
              : (box.timer || box.stopwatch) ? 
                <>
                    <TimerStopwatchContentBox
                      key={`timerStopwatchBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef.current}
                      view={view}
                      user={user}
                      multiBox={false}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.agendaBox) ? 
                <>
                    <AgendaBox
                      key={`agendaBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef.current}
                      view={view}
                      user={user}
                      multiBox={false}
                      clockKey={clockKey}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.multiBox) ? 
                <>
                    <MultiBox
                      key={`multiBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef.current}
                      view={view}
                      user={user}
                      addContentBox={addContentBox}
                      multiBox={false}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.carousel) ? 
              <>
                  <CarouselBox
                    key={`carouselBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                    content={box}
                    navbarMD={navbarMD}
                    classId={classIdRef.current}
                    view={view}
                    user={user}
                    addContentBox={addContentBox}
                    multiBox={false}
                    navigationVisible={navigationVisible}
                  />
              </>
              : (box.canva) ?
                <>
                    <CanvaBox
                      key={`canvaBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef.current}
                      view={view}
                      user={user}
                      addContentBox={addContentBox}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
               : (box.pdf) ?
               <>
                   <PdfBox
                     key={`pdfBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                     content={box}
                     navbarMD={navbarMD}
                     classId={classIdRef.current}
                     view={view}
                     user={user}
                     addContentBox={addContentBox}
                     multiBox={false}
                     updateContentBox={updateContentBox}
                     navigationVisible={navigationVisible}
                   />
               </>
               : (box.powerPoint) ?
               <>
                   <PowerpointBox
                     key={`powerPointBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                     content={box}
                     navbarMD={navbarMD}
                     classId={classIdRef.current}
                     view={view}
                     user={user}
                     addContentBox={addContentBox}
                     multiBox={false}
                     updateContentBox={updateContentBox}
                     navigationVisible={navigationVisible}
                   />
               </>
              : (box.countdown) ?
                <>
                    <CountdownBox
                      key={`countdownBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef.current}
                      view={view}
                      user={user}
                      addContentBox={addContentBox}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
              : 
                <>
                    <ContentBox
                      key={`contentBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                      content={box}
                      updateContentBox={updateContentBox}
                      setHeading={setHeading}
                      classId={classIdRef.current}
                      user={user}
                      navbarMD={navbarMD}
                      view={view}
                      multiBox={false}
                      readOnly={location.pathname.includes('/share/')}
                      singleLineFormattingToolbar={!show.marquee && !show.clock}
                      navigationVisible={navigationVisible}
                    />
                </>
              }
            </Panel>
            {
              index+1 < numberOfActiveBoxes ? (
                <PanelResizeHandle key={`panelResize-${classIdRef.current}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10px'}}>
                  <i className="fa fa-exchange" style={{margin: '-7px'}}></i>
                </PanelResizeHandle>
              ) : null
            }
          </>
      )}
    </PanelGroup>
    </Row>
  ) : loadComplete && !location.pathname.includes('/share/') ? (
    <Row style={{ flex: 1, alignContent: "center" }}>
      <Col
      md={{
        offset: 3,
        size: 6
      }}
      sm="12"
    >
      <Card body className="text-center" style={{ marginBottom: '0px', border: '1px solid red'}}>
        <CardHeader className="h2" style={{marginBottom: '0px'}}>{user.settings?.useActiveDates ? "NO CONTENT SCHEDULED" : "NO CONTENT"}</CardHeader>
        <CardBody>
          {/* <div>
            If you haven't added content to this class previously, you can get started by clicking the "Launch" button below.
          </div>
          <br></br> */}
          <div className="h6">
            {/* To create content for this date <span onClick={toggleLaunchButtonModal} style={{textDecoration: 'underline', cursor: 'pointer'}}>CLICK HERE</span>. */}
            {user.settings?.useActiveDates ? "To create content for this date click the Launch button." : "To create content for this class click the Launch button."}
          </div>
          <br></br>
          <div className="h6">
            {user.settings?.useActiveDates ? "To access your previously created content and edit the active dates," : "To access your previously created content,"} <span onClick={toggleStorageModal} style={{textDecoration: 'underline', cursor: 'pointer'}}>CLICK HERE</span>.
          </div>
        </CardBody>
      </Card>
    </Col>
    </Row>
  ) : loadComplete && location.pathname.includes('/share/') ? (
    <Row style={{ flex: 1, transform: 'translateY(-60px)' }}>
      <Col
      md={{
        offset: 0,
        size: 5
      }}
      sm="12"
    >
      <Card body className="text-center" style={{ top: '100px' , marginBottom: '0px', border: '1px solid red'}}>
        <CardHeader className="h2" style={{marginBottom: '0px'}}>
          <i className="fa fa-arrow-left" style={{marginRight: '20px'}}/>
          <i className="fa fa-arrow-left" style={{marginRight: '20px'}}/>
          <i className="fa fa-arrow-left" style={{marginRight: '20px'}}/>
          SELECT YOUR CLASS
        </CardHeader>
      </Card>
    </Col>
    </Row>
  ) : (
    <Spinner color="primary">
      Loading...
    </Spinner>
  )}
   </div>

{/**************************************************** BOTTOM NAVBAR *****************************************************/}
    {loadComplete && navigationVisible ?
      <Row className="present-navbar-launch-section">

{/**************************************************** LAUNCH BUTTON *****************************************************/}
        <Modal className="launch-modal" isOpen={launchButtonModal} toggle={toggleLaunchButtonModal} style={{minWidth: '71vw'}}>
        <ModalHeader toggle={toggleLaunchButtonModal} />
          <Card style={{marginBottom: '0px', overflowX: 'scroll'}}>
            <CardBody>
              <CardTitle className="h5">Add Content Box</CardTitle>
              <Container>
                <div style={{ display: 'flex', alignItems: 'center', height: '250px' }}>
                    <i className="nc-icon nc-minimal-left" onClick={handlePrevLaunchModalContentBoxes} style={{ marginRight: '5px', fontSize: '40px', cursor: 'pointer' }} />
                    <div style={{ display: 'flex', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', transform: `translateX(-${currentIndexLaunchModalContentBoxes * 200}px)`, transition: 'transform 0.3s ease' }}>
                            {launchButtonModalData.map((box, index) => (
                                <Col key={`iepcv2309anadf-${index}`} style={{ flex: '0 0 175px' }}>
                                    <Card
                                        className="text-center"
                                        style={{
                                            padding: hoveredContentBoxModalOption === index ? '0px' : '1px',
                                            border: hoveredContentBoxModalOption === index ? '2px solid blue' : '1px solid black',
                                            cursor: box.disabled ? null : 'pointer',
                                            width: '175px',
                                            backgroundColor: box.disabled ? 'rgba(0,0,0,0.5)' : null
                                        }}
                                        onMouseOver={() => box.disabled ? null : handleMouseOverContentBoxModalOption(index)}
                                        onMouseLeave={() => box.disabled ? null : handleMouseLeaveContentBoxModalOption()}
                                        onClick={() => {
                                            if (box.disabled) {
                                              return
                                            } else if (box.title === "Google Slides Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'Google Slides',
                                                    show: true,
                                                    userHeading: true,
                                                    slideshow: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "PowerPoint Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'PowerPoint',
                                                    show: true,
                                                    userHeading: true,
                                                    powerPoint: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "Google Doc Box") {
                                              addContentBox({
                                                  contentBoxId: createRandomId(),
                                                  startDate: date.databaseFormattedDate,
                                                  endDate: date.databaseFormattedDate,
                                                  heading: 'Google Doc',
                                                  show: true,
                                                  userHeading: true,
                                                  googleDocSheet: true,
                                                  active: true,
                                                  content: null
                                              });
                                            } else if (box.title === "Countdown Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'Countdown',
                                                    show: true,
                                                    userHeading: true,
                                                    countdown: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "Canva Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'Canva',
                                                    show: true,
                                                    userHeading: true,
                                                    canva: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "PDF Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'PDF',
                                                    show: true,
                                                    userHeading: true,
                                                    pdf: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "Timer/Stopwatch") {
                                                toggleTimerStopwatchAlert();
                                            } else if (box.title === "MultiBox") {
                                                toggleMultiBoxSelectModal();
                                            } else if (box.title === "Carousel Box") {
                                                addContentBox({
                                                    carousel: true,
                                                    active: true,
                                                    show: true,
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    content: [],
                                                    heading: 'Carousel Box'
                                                })
                                            } else if (box.title === "Agenda Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'Agenda',
                                                    show: true,
                                                    userHeading: true,
                                                    agendaBox: true,
                                                    active: true,
                                                    startTime: '00:00',
                                                    steps: []
                                                });
                                            } else if (box.title === "Text Editor Box") {
                                                addContentBox();
                                            }
                                        }}
                                    >
                                        <CardImg
                                            alt="Card image cap"
                                            src={box.image}
                                            style={{
                                                height: box.beta ? 80 : 120,
                                                marginTop: '10px',
                                                color: 'red'
                                            }}
                                            top
                                            width="100%"
                                        />
                                        <CardBody>
                                            {box.beta && 
                                              <CardTitle>
                                                {box.beta}
                                              </CardTitle>
                                            }
                                            <CardTitle className="font-weight-bold">
                                              {box.title}
                                            </CardTitle>
                                            <CardText>
                                              {box.description}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </div>
                    </div>
                    <i className="nc-icon nc-minimal-right" onClick={handleNextLaunchModalContentBoxes} style={{ marginLeft: '5px', fontSize: '40px', cursor: 'pointer' }} />
                </div>
            </Container>
              <hr></hr>
                <CardTitle className="h5">Your Templates</CardTitle>
                <Container>
                  <div style={{ display: 'flex', alignItems: 'center', height: '250px', paddingTop: '16px' }}>
                    <i className="nc-icon nc-minimal-left" onClick={templates?.length > 4 ? handlePrevLaunchModalTemplates : undefined} style={{ marginRight: '5px', fontSize: '40px', cursor: templates?.length < 5 ? null : 'pointer', color: templates?.length < 5 ? 'white' : 'black' }} />
                    <div style={{ display: 'flex', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', transform: `translateX(-${currentIndexLaunchModalTemplates * 200}px)`, transition: 'transform 0.3s ease', paddingTop: '16px' }}>
                          {templates && templates.length > 0 ? templates.map((template, index) => {
                            return (
                              <Col key={`pasdfioweb823b-${index}`} style={{ flex: '0 0 175px' }}>
                                <Card
                                  className="text-center"
                                  style={{
                                    padding: hoveredTemplateModalOption === index ? '7px' : '10px',
                                    border: hoveredTemplateModalOption === index ? '7px solid blue' : '4px solid black',
                                    cursor: 'pointer',
                                    zoom: '.33',
                                    borderRadius: '35px',
                                    width: '525px',
                                    height: '700px',
                                  }}
                                  onMouseOver={() => handleMouseOverTemplateModalOption(index)}
                                  onMouseLeave={() => handleMouseLeaveTemplateModalOption()}
                                  onClick={() => addContentBox({...template.template, contentBoxId: createRandomId()})}
                                >
                                  <Button
                                  // className="btn-round btn-icon"
                                  id="deleteContentBox"
                                  type="button"
                                  style={{ backgroundColor: 'lightcoral', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-36px', borderRadius: '100px', height: '100px', width: '100px', padding: '4px 9px', overflow: 'visible', zIndex: '9999'}}
                                  size="sm"
                                  onClick={() => deleteTemplateAlert(index)}
                                  >
                                    <i className="fa fa-trash" style={{fontSize: '50px'}}/>
                                  </Button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target="deleteContentBox"
                                      placement="left"
                                    >
                                        Delete
                                    </UncontrolledTooltip>
                                  <div style={{overflow: 'hidden'}}>
                                  {template.template.multiBox ? 
                                    <div>
                                      <br></br>
                                      <h1>{template.template.heading}</h1>
                                      <h1 style={{border: "5px solid black", height: '250px', borderRadius: '20px', marginLeft: "10px", marginRight: '10px'}}>{template.template.content.heading}</h1>
                                      <h1 style={{border: "5px solid black", height: '250px', borderRadius: '20px', marginLeft: "10px", marginRight: '10px'}}>{template.template.content.heading}</h1>
                                    </div> 
                                  : template.template.agendaBox ?
                                    <div>
                                      <AgendaBox content={template.template} readOnly/>
                                    </div>
                                  : template.template.pdf ?
                                   <div>
                                     <PdfBox content={template.template} readOnly/>
                                   </div>
                                  :
                                    <div style={{}}>
                                      <ContentBox content={template.template} readOnly/>
                                    </div>
                                  }
                                  </div>
                                </Card>
                              </Col>
                            )
                          }) : <div>No Templates Added</div>}
                        </div>
                    </div>
                    <i className="nc-icon nc-minimal-right" onClick={templates?.length > 4 ? handleNextLaunchModalTemplates : undefined} style={{ marginRight: '5px', fontSize: '40px', cursor: templates?.length < 5 ? null : 'pointer', color: templates?.length < 5 ? 'white' : 'black' }} />
                  </div>
                </Container>
                <hr></hr>
            </CardBody>
          </Card>
        </Modal>



        <Modal isOpen={openWidgetModal} toggle={toggleOpenWidgetModal} style={{minWidth: '65vw'}}>
        <ModalHeader toggle={toggleOpenWidgetModal} />
          <Card style={{marginBottom: '0px', overflowX: 'scroll'}}>
            <CardBody>
              <CardTitle className="h5">Open Widget</CardTitle>
                <Container>
                  <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'none !important', overflowX: "scroll", overflowY: 'hidden', height: '250px'}}>
                    {launchButtonWidgetData.map((box, index) => {
                      return(
                        <Col>
                          <Card 
                            className="text-center"
                            style={{
                              padding: hoveredWidgetModalOption === index ? '0px' : '1px',
                              border: hoveredWidgetModalOption === index ? '2px solid blue' : '1px solid black',
                              cursor: 'pointer',
                              width: '175px',
                            }}
                            onMouseOver={() => handleMouseOverWidgetModalOption(index)}
                            onMouseLeave={() => handleMouseLeaveWidgetModalOption()}
                            onClick={() => {
                              box.title === "Timer" ?  openToolModal(TOOL_TYPE.TIMER) :
                              box.title === "Randomizer" ?  openToolModal(TOOL_TYPE.RANDOMIZER) :
                              box.title === "Stopwatch" ?  openToolModal(TOOL_TYPE.STOPWATCH) :
                              box.title === "Whiteboard" ?  openToolModal(TOOL_TYPE.WHITEBOARD) : null
                             }}
                          >
                            <CardImg
                              alt="Card image cap"
                              src={box.image}
                              style={{
                                height: 120,
                                marginTop: '10px'
                              }}
                              top
                              width="100%"
                            />
                            <CardBody>
                              <CardTitle className="font-weight-bold">
                                {box.title}
                              </CardTitle>
                              <CardText>
                                {box.description}
                              </CardText>
                            </CardBody>                      
                          </Card>
                        </Col>
                      )
                    })}
                  </div>
                </Container>
            </CardBody>
          </Card>
        </Modal>
        <Dropdown 
          direction='up' 
          isOpen={isLaunchButtonOpen} 
          toggle={() => !share ? setIsLauncButtonOpen(!isLaunchButtonOpen) : undefined}
          // toggle={toggleLaunchButtonModal}
          style={{ top: 'auto !important', bottom: '0 !important', marginTop: '0', marginBottom: '0.125rem', cursor: share ? 'default' : 'pointer'}}
        >
          {!isLaunchButtonOpen && (
            <UncontrolledTooltip
              delay={0}
              target={"caret"}
              placement="top"
            >
              Click to Add Boxes and Tools
            </UncontrolledTooltip>
          )}
            <DropdownToggle
              className="btn bottom-navbar launch-button"
              id="caret"
            >
              <img src={logo} alt="react-logo" style={{ width: '50px', height: 'auto', marginRight: '5px' }} />
              Launch
            </DropdownToggle>

{/**************************************************** LAUNCH BUTTON DROPUP MENU *****************************************************/}
{/**************************************************** YOUR TEMPLATES *****************************************************/}
          <DropdownMenu className="launchButtonDropdown" style={{ maxHeight: '90vh', overflowY: 'scroll'}}>
            <Modal 
              isOpen={backgroundModal}
              toggle={toggleBackgroundModal}
              backdrop={false}
              scrollable
            >
              <ModalHeader toggle={toggleBackgroundModal} />
              <ModalBody style={{padding: '0'}}>
                <Container>
                  <Row>
                    <ButtonGroup className="w-100">
                      <Col xs={4}>
                        <Button
                          outline
                          block
                          onClick={() => setBackgroundChangeElement('Background')}
                          active={backgroundChangeElement === 'Background'}
                        >
                          Background
                        </Button>
                      </Col>
                      <Col xs={4}>
                        <Button
                          outline
                          block
                          onClick={() => setBackgroundChangeElement('Announcements')}
                          active={backgroundChangeElement === 'Announcements'}
                        >
                          Announcements
                        </Button>
                      </Col>
                      <Col xs={4}>
                        <Button
                          outline
                          block
                          onClick={() => setBackgroundChangeElement('Clock')}
                          active={backgroundChangeElement === 'Clock'}
                        >
                          Clock
                        </Button>
                      </Col>
                    </ButtonGroup>
                  </Row>
                  <Row xs="6">
                    {backgroundOptionsData.map((option, index) => (
                      <Col 
                        key={`2029340h8q0fbf-${index}`}
                        onClick={() => {
                          backgroundChangeElement === 'Background' ? setBackground(`url(${option.image})`) :
                          backgroundChangeElement === 'Announcements' ? setBackgroundAnnouncements(`url(${option.image})`) :
                          backgroundChangeElement === 'Clock' ? setBackgroundClock(`url(${option.image})`) :
                          null
                        }}
                        style={{ padding: '0px' }}
                      >
                        <div className="image-container">
                          <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
{/**************************************************** LAUNCH Button *****************************************************/}
            <DropdownItem onClick={toggleLaunchButtonModal}>Add Content Box</DropdownItem>
            <DropdownItem divider/>
            <DropdownItem onClick={toggleOpenWidgetModal}>Open Tool/Widget</DropdownItem>
            <DropdownItem divider/>
            <DropdownItem onClick={toggleBackgroundModal}>Change Background</DropdownItem>
            <DropdownItem divider/>
            {/* <DropdownItem onClick={null}>Change Box Order</DropdownItem>
            <DropdownItem divider/> */}
            {/* <ReorderContentBoxModal /> */}
            <Modal 
              isOpen={modal}
              toggle={toggle}
              contentClassName={
                toolType === TOOL_TYPE.WHITEBOARD ? "whiteboard-modal" 
                : toolType === TOOL_TYPE.RANDOMIZER ? "randomizer-modal"
                : null}
              modalClassName={toolType === TOOL_TYPE.WHITEBOARD ? "whiteboard"
                : toolType === TOOL_TYPE.RANDOMIZER ? "randomizer"
                : null}>
              <ModalHeader toggle={toggle} />
              <ModalBody style={{padding: '0'}}>
                {toolType === TOOL_TYPE.TIMER && <Timer />}
                {toolType === TOOL_TYPE.STOPWATCH && <Stopwatch />}
                {toolType === TOOL_TYPE.WHITEBOARD && <Whiteboard props={classIdRef}/>}
                {toolType === TOOL_TYPE.RANDOMIZER && <Randomizer props={classIdRef}/>}
              </ModalBody>
            </Modal>
            <DropdownItem onClick={() => toggleStorageModal()}>View Storage</DropdownItem>
              <Modal contentClassName="content-box-storage-table" isOpen={storageModal} toggle={toggleStorageModal}>
                <ModalHeader toggle={toggleStorageModal} style={{padding: '10px'}}>
                  <Row>
                    <Col xs="4"/>
                    <Col xs="4">
                  {user.settings?.useActiveDates ? (
                    <div className="contentBoxTableDatePicker">
                      <Button
                        className="btn-icon btn-neutral"
                        color="secondary"
                        size="md"
                        type="button"
                        onClick={() => handleDateBackward()}
                      >
                        <i className="nc-icon nc-minimal-left" />
                      </Button>
                        { showDatePickerContentBoxStorage ? (
                          <ReactDatetime
                            // timeFormat={"h:mm:a"}
                            // value={localAgendaBoxData?.startTime}
                            open
                            initialValue={date}
                            className="present-date-picker"
                            timeFormat={false}
                            closeOnSelect
                            closeOnClickOutside
                            onClose={() => setShowDatePickerContentBoxStorage(!showDatePickerContentBoxStorage)}
                            onChange={(e) => handleDateSetContentBoxStorage(e)}
                            inputProps={{
                              placeholder: "Time Picker Here"
                            }}
                          />
                        ):(
                          <div style={{marginTop: '0px', cursor: 'pointer'}} onClick={() => setShowDatePickerContentBoxStorage(!showDatePickerContentBoxStorage)}>
                            <div style={{textAlign: 'center', fontSize: '20px'}}>{date.dayName}</div>
                            <div style={{textAlign: 'center', fontSize: '15px'}}>{date.dateString}</div>
                          </div>
                        )}
                      <Button
                        className="btn-icon btn-neutral"
                        color="secondary"
                        size="md"
                        type="button"
                        onClick={() => handleDateForward()}
                      >
                        <i className="nc-icon nc-minimal-right" />
                      </Button>
                    </div>
                  )
                  : null }
                    </Col>
                    <Col xs="4"/>
                  </Row>
                </ModalHeader>
                <ModalBody>
                  <ContentBoxesTable props={classIdRef} date={date}/>
                </ModalBody>
              </Modal>
          </DropdownMenu>
        </Dropdown>
        </Row>
: null}

{/**************************************************** TOGGLE BOXES TABS *****************************************************/}
{loadComplete && navigationVisible &&
      <Row className="present-navbar-tabs-section" style={{width: user.settings?.useActiveDates === false && '78%' }}>
        <div style={{display: 'inline-flex'}}>
          {!location.pathname.includes('/share/') && boxes && boxes.map((box, index) => {
            return(
              <div key={`tab${box.contentBoxId}`} style={{marginLeft: '3px'}}>
                <ButtonDropdown 
                  isOpen={isOpenArray[index]} // Use the isOpen state from the array
                  toggle={() => handleButtonToggle(index)}
                  className="bottom-navbar"
                >
                  {/* <UncontrolledTooltip
                    delay={0}
                    target={`ContentBoxToggle-${box.contentBoxId}`}
                    placement="bottom"
                    // style={{zIndex: '9999'}}
                  >
                    {box.show ? "Click to Hide" : "Click to Show"}
                  </UncontrolledTooltip> */}
                  <DropdownToggle 
                    // color={box.show ? "primary" : 'white'}
                    className="btn bottom-navbar toggle"
                    style={{borderTopLeftRadius: '15px', width: '30px', padding: '0px', backgroundColor: box.show ? 'rgb(11, 192, 223)' : 'white', borderLeft: '2px solid rgb(11, 192, 223)', borderTop: '2px solid rgb(11, 192, 223)', borderRight: 'none !important' }}
                  >
                    <Button
                      className="btn-icon btn-neutral"
                      color="secondary"
                      id="move-box-left"
                      size="md"
                      type="button"
                      disabled={index === 0}
                      onClick={() => {
                        handleMoveLeft(box.contentBoxId, boxes[index-1].contentBoxId)
                      }}
                    >
                    <i className="nc-icon nc-minimal-left" style={{color: box.show ? 'white' : 'rgb(11, 192, 223)'}}/>
                    </Button>
                  </DropdownToggle>
                  <Button
                    key={`asldf2390ba0d9f1-${box.contentBoxId}`}
                    id={`ContentBoxToggle-${box.contentBoxId}`}
                    onClick={() => handleToggle(box)}
                    className={noBoxesSetToShowAlert === true ? "no-boxes-set-to-show-alert" : box.show ? 'btn bottom-navbar box-show' : 'btn bottom-navbar box-hide'}
                    // className={box.show ? 'btn bottom-navbar box-show' : 'btn bottom-navbar box-hide'}
                    // style={{outline: 'red !important', borderLeft: 'purple !important'}}
                  >
                    {box.heading?.length > 10 ? `${box.heading?.substring(0, 9)}...` : box.heading}
                  </Button>
                  <DropdownToggle 
                    // color="primary"
                    className="btn bottom-navbar toggle"
                    style={{borderTopRightRadius: '15px', width: '30px', padding: '0px', backgroundColor: box.show ? 'rgb(11, 192, 223)' : 'white', borderRight: '2px solid rgb(11, 192, 223)', borderTop: '2px solid rgb(11, 192, 223)', borderLeft: '0px !important' }}
                  >
                    <Button
                      className="btn-icon btn-neutral"
                      color="secondary"
                      id="move-box-right"
                      size="md"
                      type="button"
                      disabled={index === boxes.length-1}
                      onClick={() => { 
                        handleMoveRight(box.contentBoxId, boxes[index+1].contentBoxId)
                      }}
                    >
                    <i className="nc-icon nc-minimal-right" style={{color: box.show ? 'white' : 'rgb(11, 192, 223)'}}/>
                    </Button>
                  </DropdownToggle>
                  {/* <DropdownMenu className="bottom-navbar-tab-menu">
                    {boxes.length > 1 &&
                      <>
                        <DropdownItem id="move-box-left-right">
                          <Button
                            className="btn-icon btn-neutral"
                            color="secondary"
                            id="move-box-left"
                            size="md"
                            type="button"
                            disabled={index === 0}
                            onClick={() => handleMoveLeft(index)}
                          >
                          <i className="nc-icon nc-minimal-left" />
                          </Button>
                          Move Box
                          <Button
                            className="btn-icon btn-neutral"
                            color="secondary"
                            id="move-box-right"
                            size="md"
                            type="button"
                            disabled={index === boxes.length-1}
                            onClick={() => handleMoveRight(index)}
                          >
                          <i className="nc-icon nc-minimal-right" />
                          </Button>
                        </DropdownItem>
                      </>
                    }
                  </DropdownMenu> */}
                </ButtonDropdown>
              </div>
            )
          })}
        </div>
      </Row>
}
{loadComplete && navigationVisible &&
      <Row className="present-navbar-datepicker-section" style={{width: user.settings?.useActiveDates === false && '5%' }} id="present-navbar-datepicker-section">
        {user.settings?.useActiveDates ? (
          <>
            <UncontrolledTooltip
              delay={0}
              target="present-navbar-datepicker-section"
              placement="top"
            >
                Set Active Date for Present Screen
            </UncontrolledTooltip>
            <Button
              className="btn-icon btn-neutral"
              color="secondary"
              size="md"
              type="button"
              onClick={() => handleDateBackward()}
            >
              <i className="nc-icon nc-minimal-left" />
            </Button>
              { showDatePicker ? (
                <ReactDatetime
                  // timeFormat={"h:mm:a"}
                  // value={localAgendaBoxData?.startTime}
                  open
                  initialValue={date}
                  className="present-date-picker"
                  timeFormat={false}
                  closeOnSelect
                  closeOnClickOutside
                  onClose={() => setShowDatePicker(!showDatePicker)}
                  onChange={(e) => handleDateSet(e)}
                  inputProps={{
                    placeholder: "Time Picker Here"
                  }}
                />
              ):(
                <div className={noActiveBoxesAlert && "no-active-boxes-alert"} style={{marginTop: '0px', cursor: 'pointer'}} onClick={() => setShowDatePicker(!showDatePicker)}>
                  <div style={{textAlign: 'center', fontSize: '20px'}}>{date.dayName}</div>
                  <div style={{textAlign: 'center', fontSize: date.dateString.length > 19 && '12px'}}>{date.dateString}</div>
                </div>
              )}
            <Button
              className="btn-icon btn-neutral"
              color="secondary"
              size="md"
              type="button"
              onClick={() => handleDateForward()}
            >
              <i className="nc-icon nc-minimal-right" />
            </Button>
          </>
        )
        : user.settings?.demo ? 
        (
          <div onClick={() => toggleDemoCountdownTimerModal()} style={{cursor: 'pointer', color: 'white'}}>
            click
          </div>
        ) : null }
      </Row>
}
      <Modal isOpen={demoCountdownTimerModal} toggle={toggleDemoCountdownTimerModal} contentClassName="custom-modal-content" modalClassName="center-middle" backdrop={true}>
        <ModalBody style={{padding: '10px 25px'}}>
          <CountdownTimer props={demoCountdownTimerProps} />
          <Button color="primary" onClick={toggleDemoCountdownTimerModal}>Close</Button>
        </ModalBody>
      </Modal>
    </>
    : 
    <Row
      style={{
        display: 'flex',
        justifyContent: 'center', /* Horizontally center */
        alignItems: 'center', /* Vertically center */
        minHeight: '100vh' /* Ensures it covers the entire viewport height */
      }}
    >
          <Spinner color="primary">
            Loading...
          </Spinner>
    </Row>
    }
    </div>
  );
}

export default Present;